<template>
  <div
    v-if="checkConfirmed() && $route.name !== 'Cart' || isTablet"
    class="cart-button"
    @click="onOpen"
  >
    <img
      v-if="!isTablet"
      class="cart-remove-all"
      src="@/assets/img/landing/icons/cross.png"
      @click.stop="removeAll"
    />
    <span
      v-if="!isTablet"
      class="text"
    >
      Посмотреть мой заказ
    </span>
    <span
      v-if="!isTablet"
      class="icon"
    >
      <img
        src="@/assets/img/dish.svg"
        alt="dish"
      >
      <span
        v-if="cart.length > 0"
        class="badge"
      >
        {{ cart.filter((c) => {
          return c.confirmed
        }).length }}
      </span>
    </span>
    <div
      v-if="isTablet"
      class="cart-button-item"
    >
      <span
        class="text"
      >
        Мой заказ
      </span>
      <span
        class="icon"
      >
        <img
          src="@/assets/img/dish.svg"
          alt="dish"
        >
      </span>
    </div>
    <span
      v-if="isTablet && cart.length <= 0"
      class="text-subtitle"
    >
      Добавьте блюда для того что бы сделать заказ
    </span>
    <span
      v-if="isTablet && cart.length > 0"
      class="text-subtitle"
    >
      Если вы готовы сделать заказ, вы можете позвать официанта
    </span>
    <Cart
      v-if="isTablet && cart.length > 0"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '@/mixins/api'
import Cart from '@/views/Cart.vue';

export default {
  name: 'CartButton',
  components: {
    Cart
  },
  mixins: [api],
  computed: {
    ...mapGetters([
      'total'
    ]),
    ...mapState({
      cart: 'cart'
    })
  },
  methods: {
    onOpen() {
      if (!this.isTablet) {
        this.$router.push('cart');
      }
    },
    checkConfirmed() {
      return !!this.cart.find((i) => i.confirmed === true)
    },
    removeAll() {
      this.$store.commit('removeAll')
    }
  }
}
</script>
