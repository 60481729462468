<template>
  <transition name="modal-fade">
    <div
      class="mobile-menu modal-backdrop"
    >
      <header class="mobile-menu-header">
        <div class="landing-wrap__header">
          <div class="landing-header__logo">
            <router-link
              :to="{name: 'Landing'}"
              class="landing-header__logo__link"
            >
              <img
                class="landing-header__logo__img"
                src="@/assets/img/landing/qr-logo.png"
                alt="QR Menu"
              >
            </router-link>
          </div>
          <div class="header-control">
            <img
              class="header-menu-icon"
              src="@/assets/img/landing/icons/cross.png"
              @click.self="close"
            >
          </div>
          <router-link
            :to="{path: '/restaurant/labaita'}"
            class="demo-btn landing-btn"
          >
            Демо
          </router-link>
        </div>
      </header>
      <div class="mobile-menu-body">
        <div class="mobile-menu-body-group">
          <a
            v-if="fromLanding"
            href="#why-qr"
            class="mobile-menu-body-item"
            @click="close"
          >
            Почему QR-menu?
          </a>
          <router-link
            v-else
            :to="{name: 'Landing'}"
            class="mobile-menu-body-item"
          >
            Почему QR-menu?
          </router-link>
          <div class="mobile-menu-body-group-sub-items">
            <a
              v-if="fromLanding"
              href="#why-qr"
              class="mobile-menu-body-group-sub-items__el"
              @click="close"
            >
              Преимущества электронного меню
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="mobile-menu-body-group-sub-items__el"
            >
              Преимущества электронного меню
            </router-link>
            <a
              v-if="fromLanding"
              href="#how-works"
              class="mobile-menu-body-group-sub-items__el"
              @click="close"
            >
              Как работает QR-menu?
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="mobile-menu-body-group-sub-items__el"
            >
              Как работает QR-menu?
            </router-link>
            <a
              v-if="fromLanding"
              href="#additions"
              class="mobile-menu-body-group-sub-items__el"
              @click="close"
            >
              Чем отличается от обычного сайта?
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="mobile-menu-body-group-sub-items__el"
            >
              Чем отличается от обычного сайта?
            </router-link>
          </div>
        </div>
        <div class="mobile-menu-body-group">
          <a
            v-if="fromLanding"
            href="#tarifs"
            class="mobile-menu-body-item"
            @click="close"
          >
            Тарифы
          </a>
          <router-link
            v-else
            :to="{name: 'Landing'}"
            class="mobile-menu-body-item"
          >
            Тарифы
          </router-link>
        </div>
        <div class="mobile-menu-body-group">
          <a
            v-if="fromLanding"
            href="#faq"
            class="mobile-menu-body-item"
            @click="close"
          >
            FAQ
          </a>
          <router-link
            v-else
            :to="{name: 'Landing'}"
            class="mobile-menu-body-item"
          >
            FAQ
          </router-link>
        </div>
        <div class="mobile-menu-body-group">
          <a
            v-if="fromLanding"
            href="#contacts"
            class="mobile-menu-body-item"
            @click="close"
          >
            Контакты
          </a>
          <router-link
            v-else
            :to="{name: 'Landing'}"
            class="mobile-menu-body-item"
          >
            Контакты
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobileMenu',
  props: {
    fromLanding: {
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
