<template>
  <transition name="modal-fade">
    <div class="modal-alsep">
      <div class="modal-alsep__body">
        <div class="modal-alsep__close" @click="close">
          <img src="@/assets/img/icons/close-icon.svg" alt="close icon">
        </div>
        <h2 class="modal-alsep__title slide-top">
          <span>Экономия</span>
          <div>на банковских комиссиях</div>
          <div>до <span>33%</span></div>
        </h2>
        <div class="modal-alsep__terminal">
          <div class="modal-alsep__card-cless slide-top">
            <img src="@/assets/img/card-cless.svg" alt="card">
          </div>
          <div class="modal-alsep__pay slide-top">
            <img src="@/assets/img/alsep-pay.png" alt="pay">
          </div>
          <div class="modal-alsep__apple-pay slide-top">
            <img src="@/assets/img/alsep-apple-pay.png" alt="apple pay">
          </div>
          <div class="modal-alsep__visa slide-top">
            <img src="@/assets/img/alsep-visa.png" alt="visa">
          </div>
          <div class="modal-alsep__mastercard slide-top">
            <img src="@/assets/img/alsep-mastercard.png" alt="mastercard">
          </div>
          <div class="modal-alsep__card-chip slide-top">
            <img src="@/assets/img/card-chip.svg" alt="card">
          </div>
          <img src="@/assets/img/alsep-terminal.svg" alt="terminal">
        </div>
        <div class="modal-alsep__terminal-bg">
          <img src="@/assets/img/alsep-terminal-background.svg" alt="star">
        </div>
      </div>
      <a class="modal-alsep__btn"
        href="https://api.whatsapp.com/send/?phone=77473532612&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D1%85%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%B5%D0%B5%20%D0%BE%20Alsep%21&app_absent=0"
        target="_blank">Подробнее</a>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlsepModal",
  props: {},
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.modal-alsep {
  font-family: 'Montserrat';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 10000;
  padding: 22px 19px 34px 19px;

  &__body {
    position: relative;
  }

  &__title {
    max-width: 256px;
    margin: 0px auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #0C2340;
    margin-bottom: 420px;

    div {
      &:nth-of-type(2) {
        margin-top: 16px;
        text-align: center;

        span {
          font-size: 35px;
        }
      }
    }

    span {
      color: #229E54;
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  &__close {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-bottom: 5px;
  }

  &__pay {
    position: absolute;
    top: -18px;
    right: -48px;
    z-index: 10;
  }

  img {
    width: 80%;
  }

  &__apple-pay {
    position: absolute;
    top: -6px;
    left: -59px;
    z-index: 10;
  }

  img {
    width: 80%;
  }

  &__visa {
    position: absolute;
    bottom: -33px;
    right: -44px;
    z-index: 10;
  }

  img {
    width: 80%;
  }

  &__mastercard {
    position: absolute;
    bottom: -34px;
    left: -50px;
    z-index: 10;
  }

  img {
    width: 80%;
  }

  &__card-chip {
    position: absolute;
    top: 165px;
    right: 21%;
    z-index: -1;

    img {
      width: 80%;
    }
  }

  &__card-cless {
    position: absolute;
    top: 130px;
    left: -56px;
    z-index: 10;

    img {
      width: 80%;
    }
  }

  &__terminal {
    position: absolute;
    top: 186px;
    left: 50%;
    transform: translateX(-42%);
    z-index: 5;

    img {
      width: 80%;
    }
  }

  &__terminal-bg {
    position: absolute;
    top: 199px;
    left: 50%;
    transform: translateX(-40%);
    z-index: -1;

    img {
      width: 80%;
    }
  }

  &__btn {
    display: block;
    max-width: 263px;
    margin: 0px auto;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    padding: 19px 0;
    background: #194275;
    box-shadow: 0px 5.21379px 22.5931px #194275;
    border-radius: 95.5143px;
    color: #F2F2F2;
    text-decoration: none;
  }
}

.slide-top {
  animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-top {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
