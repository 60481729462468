<template>
  <transition name="modal-fade">
    <div
      class="modal-backdrop"
      @click.self="close"
    >
      <div
        v-if="timetable && contacts"
        class="modal"
      >
        <header class="modal-header">
          <div class="modal-header-wrap">
            <div class="modal-header__icon">
              <img
                :src="`${logoUrl}${restaurant.logo}`"
                class="modal-header__icon--img"
              >
            </div>
            <span class="modal-header__title">
              {{ restaurant.name }}
            </span>
          </div>
          <img
            src="@/assets/img/icons/cross-grey.png"
            @click="close"
          />
        </header>
        <div class="modal-select">
          <a
            class="modal-select slide-menu__list-item"
            :class="{'item-active': type === 'contacts'}"
            @click="type = 'contacts'"
          >
            Контакты
          </a>
          <a
            class="modal-select slide-menu__list-item"
            :class="{'item-active': type === 'timetable'}"
            @click="type = 'timetable'"
          >
            Режим работы
          </a>
        </div>
        <section class="modal-body">
          <span class="modal-body__title">
            {{ type === 'contacts' ? 'Контакты' : 'Режим работы' }}
          </span>
          <div
            class="body-wrap"
            :class="{'addOverflowY': type === 'timetable'}"
          >
            <div class="body-slider">
              <span
                id="contacts"
                class="body-mod"
              >
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Номер телефона
                  </span>
                  <a
                    class="modal-body__item--info"
                    :href="`tel:${contacts.phone}`"
                  >
                    {{ contacts.phone }}
                  </a>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Адрес
                  </span>
                  <span class="modal-body__item--info">
                    {{ contacts.address }}
                  </span>
                </div>
                <div
                  v-if="contacts.work_email"
                  class="modal-body__item"
                >
                  <span class="modal-body__item--title">
                    e-mail
                  </span>
                  <span class="modal-body__item--info">
                    {{ contacts.work_email }}
                  </span>
                </div>
                <div
                  v-if="contacts"
                  class="modal-body__item__contacts"
                >
                  <a
                    v-if="contacts.insta"
                    class="restaurant-head__links__item"
                    :href="contacts.insta"
                    target="_blank"
                  >
                    <img
                      class="restaurant-head__links__item__img"
                      src="@/assets/img/instagram.png"
                    >
                  </a>
                  <a
                    v-if="contacts.facebook"
                    class="restaurant-head__links__item"
                    :href="contacts.facebook"
                    target="_blank"
                  >
                    <img
                      class="restaurant-head__links__item__img"
                      src="@/assets/img/facebook.svg"
                    >
                  </a>
                  <a
                    v-if="contacts.vk"
                    class="restaurant-head__links__item"
                    :href="contacts.vk"
                    target="_blank"
                  >
                    <img
                      class="restaurant-head__links__item__img"
                      src="@/assets/img/vk.svg"
                    >
                  </a>
                  <a
                    v-if="contacts.whatsapp"
                    class="restaurant-head__links__item"
                    :href="contacts.whatsapp"
                    target="_blank"
                  >
                    <img
                      class="restaurant-head__links__item__img"
                      src="@/assets/img/WhatsApp.png"
                    >
                  </a>
                </div>
              </span>
              <span
                id="schedule"
                class="body-mod"
              >
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Понедельник
                  </span>
                  <span
                    v-if="timetable[0].hour_b && timetable[0].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[0].hour_b.substr(0, 5)
                      + ' до ' + timetable[0].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Вторник
                  </span>
                  <span
                    v-if="timetable[1].hour_b && timetable[1].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[1].hour_b.substr(0, 5)
                      + ' до ' + timetable[1].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Среда
                  </span>
                  <span
                    v-if="timetable[2].hour_b && timetable[2].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[2].hour_b.substr(0, 5)
                      + ' до ' + timetable[2].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Четверг
                  </span>
                  <span
                    v-if="timetable[3].hour_b && timetable[3].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[3].hour_b.substr(0, 5)
                      + ' до ' + timetable[3].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Пятница
                  </span>
                  <span
                    v-if="timetable[4].hour_b && timetable[4].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[4].hour_b.substr(0, 5)
                      + ' до ' + timetable[4].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Суббота
                  </span>
                  <span
                    v-if="timetable[5].hour_b && timetable[5].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[5].hour_b.substr(0, 5)
                      + ' до ' + timetable[5].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
                <div class="modal-body__item">
                  <span class="modal-body__item--title">
                    Воскресенье
                  </span>
                  <span
                    v-if="timetable[6].hour_b && timetable[6].hour_e"
                    class="modal-body__item--info"
                  >
                    {{ 'с ' + timetable[6].hour_b.substr(0, 5)
                      + ' до ' + timetable[6].hour_e.substr(0, 5) }}
                  </span>
                  <span
                    v-else
                    class="modal-body__item--info"
                  >
                    Выходной
                  </span>
                </div>
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'

export default {
  name: 'Modal',
  mixins: [api],
  data() {
    return {
      type: 'contacts'
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant',
      contacts: 'contacts',
      timetable: 'timetable'
    })
  },
  watch: {
    type: function horizontalScrolling() {
      const elem = document.querySelector('.body-slider');
      const pageY = window.screen.height;
      const pageX = document.querySelector('.modal-body').offsetWidth;
      if (this.type === 'timetable') {
        this.smoothScroll(pageY, pageX, elem, 'next')
      } else {
        this.smoothScroll(pageY, 0, elem, 'prev', pageX)
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    smoothScroll(y, pageX, element, direction, x = 0) {
      const i = x || 0;
      if (direction === 'next') {
        if (i < pageX) {
          setTimeout(() => {
            element.scrollTo(i, y);
            this.smoothScroll(y, pageX, element, 'next', i + 20);
          }, 10);
        }
      } if (direction === 'prev') {
        if (i > pageX - 25) {
          setTimeout(() => {
            element.scrollTo(i, y);
            this.smoothScroll(y, pageX, element, 'prev', i - 20);
          }, 10);
        }
      }
    }
  },
};
</script>

<style lang="scss">
  .body-wrap {
    overflow: hidden;
  }
  .body-slider {
    display: flex;
    overflow-x: hidden;
    /* scroll-snap-type: x mandatory; */
    /* scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; */
    height: 100%;
  }

  .body-mod {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 4px solid white; /* should match background, can't be transparent */
      background-color: #E3E4E6;
    }
  }
  .modal-body__item__contacts {
    display: grid;
    margin-top: 24px;
    width: fit-content;
    grid-column-gap: 20px;
  }
</style>
