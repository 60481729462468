<template>
  <div class="item-info-back">
    <button
      class="item-info-back__btn"
      @click="$router.push({path: `/a${restaurant.alias}`,
                            query: {item: itemId}})"
    >
      <img
        src="@/assets/img/back_btn.svg"
        class="back-arrow"
      >
      <span class="back-text">
        Назад к меню
      </span>
    </button>
    <CartDesktop
      v-if="!isMobile"
      class="desktop-cart-button"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CartDesktop from '@/components/desktop/CartDesktop.vue'
import api from '@/mixins/api'

export default {
  name: 'BackHeader',
  components: {
    CartDesktop
  },
  mixins: [api],
  props: {
    itemId: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    ...mapState({
      restaurant: 'restaurant'
    })
  },
}
</script>
