<template>
  <div class="restaurant-head">
    <div
      v-if="isTablet"
      class="restaurant-head-icon"
    >
      <img
        :src="`${logoUrl}${restaurant.logo}`"
        alt="restaurant"
      >
    </div>
    <div class="restaurant-head-wrap">
      <span class="restaurant-head__title">
        {{ restaurant.name }}
      </span>
      <HeadDetails
        v-if="!isMobile"
      />
      <HeadDetailsMobile
        v-else
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'
import HeadDetails from '@/components/desktop/HeadDetails.vue'
import HeadDetailsMobile from '@/components/mobile/HeadDetails.vue'

export default {
  name: 'RestaurantHead',
  components: {
    HeadDetails,
    HeadDetailsMobile
  },
  mixins: [api],
  computed: {
    ...mapGetters({
      restaurant: 'restaurant'
    })
  }
}
</script>
