<template>
  <div
    :style="css"
    class="loading_wrapper"
  >
    <img
      src="@/assets/img/icons/loadLogo.svg"
      class="loading_logo"
    />
    <div class="loader_wrap">
      <div class="loadText">
        <div
          :style="`--percent: ${percent}%`"
          class="loadText-title"
        >
          IT РЕШЕНИЯ В ЛЮБОЙ СФЕРЕ
        </div>
        <div class="loadText-subtitle">
          <span>Загрузка</span>
          <span>{{ percent }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Loading',
  props: {
    css: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      percent: 0,
      load: false
    }
  },
  mounted() {
    const loading = setInterval(() => {
      this.percent += 2
      if (this.percent >= 100) {
        clearInterval(loading)
        // this.load = true
        this.$emit('load', true)
      }
    }, 30)
  }
};
</script>

<style lang='scss'>

.loadText-title {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  -webkit-background-clip: text;
  background-image: url('~@/assets/img/loader_bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom 0px right var(--percent);
  background-size: 200% 100%;
  color: transparent;
}

.loadText-subtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 7px;
  font-size: 16px;
  color: #9C9C9C;
  font-family: 'Qanelas Soft';
}

.loading {
  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &_logo {
    width: 130px;
  }
}

.loader_wrap {
  font-size: 35px;
  font-weight: 600;
  margin: 15px 0 10px;
}

</style>
