import Vue from 'vue'
import VueRouter from 'vue-router'
import Restaurant from '@/views/Restaurant.vue'
import Item from '@/views/Item.vue'
import Cart from '@/views/Cart.vue'
import Landing from '@/views/Landing.vue'
/* eslint-disable */
import store from '@/store/index'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/restaurant/:alias',
    name: 'Restaurant',
    props: true,
    component: Restaurant
  },
  {
    path: '/a*',
    name: 'RestaurantAlias',
    props: true,
    component: Restaurant
  },
  {
    path: '/item',
    name: 'Item',
    component: Item,
    beforeEnter: (to, from, next) => {
      if (from.name) {
        next()
      } else {
        next({ path: `/a${store.state.restaurant.alias}` })
      }
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => {
      if (from.name) {
        next()
      } else {
        next({ path: `/a${store.state.restaurant.alias}` })
      }
    }
  },
  {
    path: '/no_restaurant',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
