<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
      <div class="modal-tarif-choose">
        <div class="modal-tarif-choose__body">
          <div class="modal-tarif-choose__body--title">
            <span class="modal-tarif-choose__body--title--text">
              Благодарим за регистрацию!
            </span>
            <span class="modal-tarif-choose__body--close" @click="close"> </span>
          </div>
          <div class="modal-tarif-choose__body--subtitle">
            <span class="modal-tarif-choose__body--subtitle--text">
              Пожалуйста, в ближайшее время проверьте свою почту . Мы отправим Вам письмо с паролем
              для входа в Личный кабинет ресторатора.
            </span>
            <span class="modal-tarif-choose__body--subtitle--text">
              Если Вы не нашли письмо, проверьте, пожалуйста, папку "Спам".
            </span>
          </div>
          <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
          <div class="modal-tarif-choose__body--title title-2">
            <span class="modal-tarif-choose__body--title--text">
              Наши контакты:
            </span>
          </div>
          <div class="modal-tarif-choose__body--contacts-item">
            <span class="modal-tarif-choose__body--contacts-item--title">
              почта:
            </span>
            <span class="modal-tarif-choose__body--contacts-item--body">
              a.abylaikhan@qlt.kz
            </span>
          </div>
          <div class="modal-tarif-choose__body--contacts-item">
            <span class="modal-tarif-choose__body--contacts-item--title">
              телефон:
            </span>
            <span class="modal-tarif-choose__body--contacts-item--body">
              8-747-353-26-12
            </span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/mixins/api";

export default {
  name: "RegistrationGratitude",
  mixins: [api],
  props: {},
  data() {},
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.content__icon--img {
  margin: 0;
  margin-bottom: 25px;
}
.modal-tarif-choose__body--subtitle--text {
  display: block;
  &:first-child {
    margin-bottom: 20px;
  }
}
.modal-tarif-choose__body--contacts-item {
  margin-bottom: 3px;
}
.modal-tarif-choose__body--contacts-item--title {
  font-weight: 600;
}
.title-2 {
  margin-bottom: 14px;
}
</style>
