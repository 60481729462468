<template>
  <div class="rest_wrap">
    <Loading v-if="!load && !isLoading" @load="changeLoad" />
    <div v-if="loaded && load && !isLoading" class="wrap">
      <RestaurantHead v-if="contacts && timetable" />
      <FilterHead :offset="offset" @searched="searchItem(arguments[0])" />
      <Banners v-if="restaurant.rel_banners.length" :logo="logo" @modal_opened="showModalItem(arguments[0])" />
      <div class="restaurant-body">
        <template v-for="(category, indexCat) in categories">
          <section v-if="category.menu.length" :id="`category-${category.id}`" :key="`cat-${indexCat}`"
            class="restaurant-body__menu-category">
            <div class="restaurant-body__menu-category-title-wrapper">
              <div class="restaurant-body__menu-category-title">
                <span class="restaurant-body__menu-category-title__text">
                  {{ category.name }}
                </span>
              </div>
              <!-- <a
                v-if="indexCat === 0 || indexCat % 4 === 0"
                class="restaurant-body__menu-category-title-qlt"
                target="_blank"
                @click="showQltModal"
              >
                <img src="@/assets/img/landing/qlt-logo-mini.png" alt="" />
              </a> -->
              <a v-if="indexCat === 0 || indexCat % 4 === 0" class="restaurant-body__menu-category-title-alsep"
                target="_blank" @click="showAlsepModal">
                <img src="@/assets/img/landing/alsep-logo.png" alt="" />
              </a>
            </div>
            <div class="restaurant-body__menu-category__list">
              <template v-for="(item, index) in category.menu">
                <MenuItem :key="index" :item="item" :is-mobile="isMobile" @modal_opened="showModalItem(arguments[0])"
                  @modal_details_opened="showModalDetails(arguments[0])"
                  @sameAdded="addSame(arguments[0], arguments[1])" />
                <MenuItem v-for="similar in cart.filter(s => s.id.toString().includes(`_${item.id}`))"
                  :key="'similar' + similar.id" :item="similar" class="similars-mobile"
                  @modal_opened="showModalItem(arguments[0])" @modal_details_opened="showModalDetails(arguments[0])"
                  @sameAdded="addSame(arguments[0], arguments[1])" />
              </template>
            </div>
          </section>
        </template>
      </div>
      <MenuItemDetails v-if="isModalDetailsVisible" :details="details" @closed="closeModalDetails" />
      <MenuItemModal v-if="isModalItemVisible" :image="itemModal.logo" :name="itemModal.name" :promotion="promotion"
        :ingredients="itemModal.ingredients" :description="itemModal.description" @close="closeModalItem" />
      <NoResults v-show="isNoResultsVisible" @closed="closeNoResults" />
      <AlsepModal v-show="alsepModalVisible" @close="closeAlsepModal" />
      <!-- <QltModal v-show="qltModalVisible" @close="closeQltModal" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RestaurantHead from "@/components/RestaurantHead.vue";
import MenuItem from "@/components/MenuItem.vue";
import MenuItemDetails from "@/components/modals/MenuItemDetails.vue";
import MenuItemModal from "@/components/modals/MenuItemModal.vue";
import api from "@/mixins/api";
import NoResults from "@/components/modals/NoResults.vue";
import FilterHead from "@/components/Filter.vue";
import Banners from "@/components/mobile/Banners.vue";
import Loading from "@/components/Loading.vue";
import AlsepModal from "@/components/modals/Alsep.vue";
// import QltModal from "@/components/modals/Qlt.vue";

export default {
  name: "Restaurant",
  components: {
    FilterHead,
    NoResults,
    RestaurantHead,
    MenuItem,
    MenuItemDetails,
    MenuItemModal,
    Banners,
    Loading,
    AlsepModal
    // QltModal
  },
  mixins: [api],
  data: () => ({
    isModalItemVisible: false,
    isModalDetailsVisible: false,
    isNoResultsVisible: false,
    offset: 0,
    promotion: false,
    alsepModalVisible: false,
    // qltModalVisible: false,
    itemModal: {
      logo: "",
      name: "",
      ingredients: "",
      description: ""
    },
    details: null,
    loaded: false,
    scrollPosition: null,
    time: null
  }),
  computed: {
    ...mapGetters({
      cart: "cart",
      restaurant: "restaurant",
      contacts: "contacts",
      timetable: "timetable",
      categories: "categories",
      menu: "menu",
      alias: "alias",
      isLoading: "isLoading",
      load: "load"
    }),
    logo() {
      return window.location.hostname === "mqr.kz"
        ? "https://adminqr.qlt.kz/"
        : "http://37.77.104.231:3636/";
    }
  },
  watch: {
    isModalItemVisible: function modalItemVisible() {
      this.notScrolling(this.isModalItemVisible);
    },
    isModalDetailsVisible: function modalDetailsVisible() {
      this.notScrolling(this.isModalDetailsVisible);
    },
    isNoResultsVisible: function noResultsVisible() {
      this.notScrolling(this.isNoResultsVisible);
    },
    // qltModalVisible: function noqltModalVisible() {
    //   this.notScrolling(this.qltModalVisible);
    // },
    alsepModalVisible: function noAlsepModalVisible() {
      this.notScrolling(this.alsepModalVisible);
    }
  },
  async mounted() {
    this.$store.commit("load", false);
    const currAlias = this.$route.path.split("/a")[1];
    if (currAlias !== this.alias) {
      this.$store.commit("removeAll");
    }
    await this.$store.dispatch("getRestaurant", currAlias);
    this.loaded = true;
    this.$nextTick(() => {
      const elem = document.getElementById("nav");
      this.offset = elem.offsetHeight + 20;
    });
    this.$nextTick(() => {
      if (this.$route.query.item) {
        const element = document.querySelector(`#item-${this.$route.query.item}`);
        window.scrollTo(0, element.offsetTop - 60);
      }
    });
  },
  methods: {
    changeLoad(value) {
      this.$store.commit("load", value);
      /* this.load = value; */
    },
    ...mapActions(["addItemToCart"]),
    addSame(item, num) {
      const newItem = JSON.parse(JSON.stringify(item));
      newItem.id = `${num}_${newItem.id}`;
      newItem.selected_sauce = null;
      this.addItemToCart(newItem);
    },
    notScrolling(modalName) {
      if (!modalName) {
        document.body.classList.remove("not-scrolling");
        document.body.style.top = "";
        window.scroll(0, this.scrollPosition);
        this.scrollPosition = 0;
      } else {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        this.scrollPosition = scrollPosition;
        document.body.classList.add("not-scrolling");
        document.body.style.top = `-${scrollPosition}px`;
      }
    },
    showModalItem(item) {
      if (item.photo) {
        this.itemModal.logo = this.logoUrl + item.photo;
        this.itemModal.ingredients = item.ingredient;
        this.promotion = false;
      } else {
        this.itemModal.logo = this.logo + item.image;
        this.itemModal.description = item.description;
        this.promotion = true;
      }
      this.itemModal.name = item.name;
      this.isModalItemVisible = true;
    },
    closeModalItem() {
      this.isModalItemVisible = false;
    },
    showModalDetails(item) {
      this.details = item;
      this.isModalDetailsVisible = true;
    },
    closeModalDetails() {
      this.isModalDetailsVisible = false;
    },
    closeNoResults() {
      this.isNoResultsVisible = false;
    },
    searchItem(val) {
      const regex = new RegExp(val, "i");
      const firstMatch = this.menu.filter(i => !!i.name.toLowerCase().match(regex))[0];
      if (firstMatch) {
        const element = document.querySelector(`#item-${firstMatch.id}`);
        window.scrollTo(0, element.offsetTop - 100);
      } else {
        this.isNoResultsVisible = true;
      }
    },
    timeout() {
      this.time = setTimeout(() => {
        this.alsepModalVisible = false;
        // this.qltModalVisible = false;
      }, 5000);
    },
    showAlsepModal() {
      this.alsepModalVisible = true;
      // this.timeout();
    },
    closeAlsepModal() {
      this.alsepModalVisible = false;
      clearTimeout(this.time);
    }
    // showQltModal() {
    //   this.qltModalVisible = true;
    //   // this.timeout();
    // },
    // closeQltModal() {
    //   this.qltModalVisible = false;
    //   clearTimeout(this.time);
    // }
  }
};
</script>

<style lang="scss">
.sticky-head {
  position: sticky;
  top: -1px;
  z-index: 1000;
  background: #ffffff;
  display: flex;
  border-top: 1px solid #e3e4e6;
  box-sizing: border-box;
  /* @media screen and (min-width: 812px){
      background: unset;
      display: unset;
      height: unset;
      box-sizing: unset;
  } */
}

.restaurant-body__menu-category-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.restaurant-body__menu-category-title-alsep {
  transform: translateX(7px);

  img {
    display: block;
    animation: qltAnimation 2s infinite;
  }
}

// .restaurant-body__menu-category-title-qlt {
//   background: #000;
//   height: 40px;
//   border-radius: 100px 0 0 100px;
//   padding: 9px 15px;
//   box-sizing: border-box;
//   display: block;
//   cursor: pointer;
//   img {
//     height: 100%;
//     animation: qltAnimation 2s infinite;
//   }
// }

@keyframes qltAnimation {
  0% {
    transform: translate(0, 0);
  }

  30% {
    transform: translate(0, 0);
  }

  33% {
    transform: translate(-3px, 0);
  }

  36% {
    transform: translate(3px, 0);
  }

  39% {
    transform: translate(-2px, 0);
  }

  42% {
    transform: translate(2px, 0);
  }

  45% {
    transform: translate(-1px, 0);
  }

  48% {
    transform: translate(1px, 0);
  }

  51% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>
