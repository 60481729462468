import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import Vue from 'vue'

Sentry.init({
  dsn: 'https://6b559264be1541fbb692d135098cd7b0@o451259.'
    + 'ingest.sentry.io/5475799',
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      tracing: true,
      tracingOptions: {
        trackComponents: true
      }
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0
});
