<template>
  <div>
    <footer class="landing-footer">
      <div class="landing-footer--box">
        <div class="landing-wrap wrap-footer">
          <div class="landing-footer--box__flex">
            <div class="footer-social-icons">
              <div class="footer-social-icons-qr">
                <img src="@/assets/img/landing/qr-logo.png">
              </div>
              <div class="footer-social-icons-list">
                <a
                  href="#"
                  class="footer-social-icons-list-el"
                >
                  <img
                    src="@/assets/img/landing/icons/youtube.png"
                  >
                </a>
                <a
                  href="https://www.instagram.com/mqr.kz/"
                  target="_blank"
                  class="footer-social-icons-list-el"
                >
                  <img
                    src="@/assets/img/landing/icons/instagram_2.svg"
                  >
                </a>
              </div>
            </div>
            <ul class="footer-list">
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#"
                  class="footer-list-sections-sec-link"
                >
                  Главная
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Главная
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#why-qr"
                  class="footer-list-sections-sec-link"
                >
                  Почему QR-menu?
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Почему QR-menu?
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#tarifs"
                  class="footer-list-sections-sec-link"
                >
                  Тарифы
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Тарифы
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#faq"
                  class="footer-list-sections-sec-link"
                >
                  FAQ
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  FAQ
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#contacts"
                  class="footer-list-sections-sec-link"
                >
                  Контакты
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Контакты
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#why-qr"
                  class="footer-list-sections-sec-link"
                >
                  Преимущества электронного меню
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Преимущества электронного меню
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#how-works"
                  class="footer-list-sections-sec-link"
                >
                  Как работает QR-menu?
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Как работает QR-menu?
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <a
                  v-if="fromLanding"
                  href="#additions"
                  class="footer-list-sections-sec-link"
                >
                  Чем отличается от обычного сайта?
                </a>
                <router-link
                  v-else
                  :to="{name: 'Landing'}"
                  class="footer-list-sections-sec-link"
                >
                  Чем отличается от обычного сайта?
                </router-link>
              </li>
              <li class="footer-list-sections-sec">
                <button
                  class="footer-btn landing-btn"
                >
                  <router-link
                    :to="{path: '/alabaita'}"
                    class="footer-btn-link"
                  >
                    Посмотреть демо
                  </router-link>
                </button>
              </li>
            </ul>
            <div class="footer-icons-qlt">
              <span>
                Разработчик
              </span>
              <a
                href="https://qlt.kz/"
                target="_blank"
              >
                <img src="@/assets/img/landing/creator.png">
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <span class="landing--footer--copyright">
      <div class="landing--footer--copyright__text">
        mqr.kz 2020 Все права защищены
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'FooterMain',
  props: {
    fromLanding: {
      type: Boolean
    }
  },
}
</script>
