<template>
  <div
    class="cart-button"
  >
    <span class="text-desktop">
      Мой заказ
    </span>
    <img
      class="cart-remove-all"
      src="@/assets/img/landing/icons/cross.png"
      @click="removeAll"
    />
    <span class="desktop-cart-description">
      Если вы готовы сделать заказ, можете позвать официанта
    </span>
    <span class="icon">
      <img
        src="@/assets/img/dish.svg"
        alt="dish"
      >
      <span
        v-if="cart.length > 0"
        class="badge"
      >
        {{ cart.filter((c) => {
          return c.confirmed
        }).length }}
      </span>
    </span>
    <div class="cart-desktop">
      <div class="menu-category__list-desktop">
        <template v-for="(item, index) in cart.filter((i) => i.confirmed)">
          <MenuItemDesktop
            :key="index"
            :item="item"
            @sameAdded="addSame(arguments[0], arguments[1])"
          />
        </template>
      </div>
      <div class="total">
        <div
          v-if="restaurant.service"
          class="total-service"
        >
          <span class="total-service-text">
            Обслуживание
          </span>
          <span class="total-service-percent">
            {{ restaurant.service }}%
          </span>
        </div>
        <div class="total-price">
          <span class="left">Итого</span>
          <span class="right">{{ total + (total * restaurant.service / 100)
            | formatPrice(currency) }}</span>
        </div>
        <div
          v-if="restaurant.comment"
          class="total-comment"
        >
          <span class="total-comment-text">
            {{ restaurant.comment }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MenuItemDesktop from '@/components/desktop/MenuItemDesktop.vue'

export default {
  name: 'CartDesktop',
  components: {
    MenuItemDesktop
  },
  filters: {
    formatPrice(price, currency) {
      return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      ${currency}`
    }
  },
  computed: {
    ...mapGetters([
      'total'
    ]),
    ...mapState({
      cart: 'cart',
      restaurant: 'restaurant'
    }),
    currency() {
      const first = this.cart[0]
      return first ? first.currency : ''
    }
  },
  methods: {
    checkConfirmed() {
      return !!this.cart.find((i) => i.confirmed === true)
    },
    addSame(item, num) {
      const newItem = JSON.parse(JSON.stringify(item))
      newItem.id = `${num}_${newItem.id}`
      newItem.selected_sauce = null
      this.$store.dispatch('addItemToCartConfirmed', newItem)
    },
    removeAll() {
      this.$store.commit('removeAll')
    }
  }
}
</script>
