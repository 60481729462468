<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
      <div class="modal-tarif-choose">
        <div class="modal-tarif-choose__body">
          <div class="modal-tarif-choose__body--title">
            <span class="modal-tarif-choose__body--title--text">
              {{
                type === "free"
                  ? "Попробуйте бесплатно навсегда"
                  : "Оставьте нам заявку"
              }}
            </span>
            <span class="modal-tarif-choose__body--close" @click="close">
            </span>
          </div>
          <div
            v-if="type === 'free'"
            class="modal-tarif-choose__body--subtitle"
          >
            <span class="modal-tarif-choose__body--subtitle--text">
              Инновационное бесконтактное цифровое меню для ресторанов, баров и
              кафe
            </span>
          </div>
          <form @submit="sendForm" id="free">
            <div
              :class="
                type !== 'free'
                  ? 'modal-tarif-choose__body--form'
                  : 'modal-tarif-choose__body--form free'
              "
            >
              <div class="modal-tarif-choose__body--form--group">
                <label class="modal-tarif-choose__body--form--group__label">
                  ФИО
                </label>
                <input
                  v-model="form.fio"
                  class="modal-tarif-choose__body--form--group__input"
                  placeholder="Имя"
                />
              </div>
              <div class="modal-tarif-choose__body--form--group">
                <label class="modal-tarif-choose__body--form--group__label">
                  Номер телефона
                </label>
                <input
                  v-model="form.telephone"
                  v-mask="'+7-###-###-##-##'"
                  type="tel"
                  class="modal-tarif-choose__body--form--group__input"
                  placeholder="+7___-___-__-__"
                />
              </div>
              <div class="modal-tarif-choose__body--form--group">
                <label class="modal-tarif-choose__body--form--group__label">
                  Почта
                </label>
                <input
                  v-model="form.email"
                  type="email"
                  class="modal-tarif-choose__body--form--group__input"
                  placeholder="E-mail(обязательно)"
                />
              </div>
              <div class="modal-tarif-choose__body--form--group">
                <label class="modal-tarif-choose__body--form--group__label">
                  Название заведения
                </label>
                <input
                  v-model="form.restoran_name"
                  class="modal-tarif-choose__body--form--group__input"
                  placeholder="Название заведения"
                />
              </div>
            </div>
          </form>
          <slot name="prices"> </slot>
          <button
            form="free"
            class="modal-tarif-choose__body--btn"
            :class="{ 'btn-disabled': Object.values(form).some(o => !o) }"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from "@/mixins/api";

export default {
  name: "ChooseTarif",
  mixins: [api],
  props: {
    type: {
      type: String,
      default: "free",
    },
  },
  data() {
    return {
      form: {
        fio: "",
        email: "",
        telephone: "",
        restoran_name: "",
      },
    };
  },
  methods: {
    close() {
      this.resetForm();
      this.$emit("close");
    },
    sendForm(e) {
      e.preventDefault()
      
        this.sendTarifForm(this.form)
        .then(() => {
          this.resetForm();
          this.$emit("sent");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.form.fio = "";
      this.form.email = "";
      this.form.telephone = "";
      this.form.restoran_name = "";
    },
  },
};
</script>

<style scoped lang="scss"></style>
