const qa = [
  {
    question: '1. Чем ваш сервис отличается от обычного сайта заведения?',
    answer: 'Создание сайта с функцией оформления заказа стоит дорого. '
      + 'Наше решение намного дешевле. Удобство QR-Menu также заключается в '
      + 'быстром открытии электронного меню с телефона по QR-коду. '
      + 'Тогда как  сайт нужно искать в браузере или находить по '
      + 'ссылке из стороннего сервиса.',
    toggled: false
  },
  {
    question: '2. Чем ваш сервис отличается от электронного меню '
      + 'в PDF-формате?',
    answer: 'В нашем сервисе клиент может выбирать блюда, '
      + 'формировать заказ и считать '
      + 'сумму заказа автоматически. \n'
      + 'А разделение меню по категориям делает поиск '
      + 'нужного блюда в меню удобнее и быстрее.',
    toggled: false
  },
  {
    question: '3. Для чего нужна административная панель в QR-menu?',
    answer: 'Панель управления нужна для обновления и '
      + 'редактирования позиций в меню.',
    toggled: false
  },
  {
    question: '4. Надо ли менять QR-код, если поменялось меню?',
    answer: 'Нет, QR-код менять не надо. QR-код останется прежним, '
      + 'даже если вы замените все позиции в меню на новые.',
    toggled: false
  },
  {
    question: '5. Какие дополнительные функции для бизнеса '
      + 'есть у вашего сервиса?',
    answer: 'У нас много планов по развитию нашего сервиса и '
      + 'дополнению новыми функциями. Подключение новых '
      + 'функций будет происходить согласно требованиям бизнеса.',
    toggled: false
  },
  {
    question: '6. Сколько нужно ждать электронное меню?',
    answer: 'Загрузка электронного меню занимает до двух дней.',
    toggled: false
  },
  {
    question: '7. Что нужно сделать, чтобы получить электронное меню?',
    answer: 'Чтобы получить электронное меню, Вам нужно ввести свой '
      + 'телефон в блоке (link) и нажать на кнопку «получить меню». '
      + 'После наш менеджер свяжается с Вами.',
    toggled: false
  }
]

export default qa
