<template>
  <div class="restaurant-head__details-mobile">
    <div
      class="restaurant-head__details-mobile__about"
      @click="showModal"
    >
      <img src="@/assets/img/icons/contact.svg" />
      О ресторане
    </div>
    <Contacts
      v-show="isModalVisible"
      :restaurant-name="restaurant.name"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Contacts from '@/components/modals/Contacts.vue'

export default {
  name: 'HeadDetailsMobile',
  components: {
    Contacts
  },
  data() {
    return {
      isModalVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant'
    })
  },
  watch: {
    isModalVisible: () => {
      document.body.classList.toggle('not-scrolling')
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  }
}
</script>
