import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { get } from '@/api/requests'
/* eslint-disable */
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    restaurant: null,
    cart: [],
    currentCategory: null,
    categories: [],
    menu: [],
    partners: [],
    isLoading: false,
    load: false
  },

  getters: {
    alias(state) {
      return state.restaurant ? state.restaurant.alias : null
    },
    restaurant: (state) => state.restaurant,
    contacts: (state) => state.restaurant.rel_data,
    timetable: (state) => state.restaurant.rel_timetables,
    currentCategory: (state) => state.currentCategory,
    categories: (state) => state.categories,
    menu: (state) => state.menu,
    cart: (state) => state.cart,
    partners: (state) => state.partners,
    total: (state) => state.cart
      .reduce((acc, item) => {
        const optionsTotal = item.selected_options
          .reduce((a, opt) => a + opt.price, 0)
        return acc + (item.confirmed
          ? ((item.discounted_price ?? item.price) * item.quantity + optionsTotal) : 0)
      }, 0),
    fromCart(state) {
      return (id) => (state.cart.find((i) => i.id === id) ?? null)
    },
    isLoading: (state) => state.isLoading,
    load: (state) => state.load
  },

  actions: {
    async getRestaurant({ commit, dispatch, state }, alias) {
      commit('start')
      const response = await get(`/restoran?alias=${alias}`)
      const restaurant = response.data.result.data[0]
      if (restaurant) {
        commit('setRestaurant', restaurant)
        const categories = restaurant.rel_menu_cats
        commit('setCategories', categories)
        if (!state.currentCategory) {
          const category = categories[0]
          commit('setCategory', category)
        }
        await dispatch('getMenu', restaurant.id)
      } else {
        commit('resetState')
        router.push({ name: 'NotFound' })
      }
    },

    async getMenu({ commit, dispatch }, id) {
      // commit('start')
      const response = await get(`/menu?restoran_id=${id}`)
      const menu = response.data
      commit('setMenu', menu)
      commit('assignMenu')
    },

    addItemToCart({ commit }, item) {
      const newItem = {
        ...item,
        quantity: 1,
        confirmed: false,
        selected_options: []
      }
      commit('pushToCart', newItem)
    },
    addItemToCartConfirmed({ commit }, item) {
      const newItem = {
        ...item,
        quantity: 1,
        confirmed: true,
        selected_options: []
      }
      commit('pushToCart', newItem)
    },
    async getPartners({ commit }) {
      commit('start')
      const response = await get('/partner')
      commit('setPartners', response.data.result)
    }
  },

  mutations: {
    load: (state,payload) => {
      state.load = payload
    },
    start: (state) => {
      state.isLoading = true
    },
    increaseCartItem: (state, id) => {
      state.cart.find((item) => item.id === id).quantity += 1
    },
    decreaseCartItem: (state, id) => {
      const item = state.cart.find((el) => el.id === id)
      if (item.quantity === 1) {
        const index = state.cart.findIndex((i) => i.id === id)
        state.cart.splice(index, 1)
      } else {
        item.quantity -= 1
      }
    },

    setRestaurant: (state, payload) => {
      state.restaurant = payload
      state.isLoading = false
    },

    setCategory: (state, payload) => {
      state.currentCategory = payload
    },

    setCategories: (state, payload) => {
      state.categories = payload
    },

    setMenu: (state, payload) => {
      state.menu = payload
      state.isLoading = false
    },

    pushToCart: (state, payload) => {
      state.cart.push(payload)
    },

    assignMenu: (state) => {
      state.categories.map((i) => {
        const result = { ...i }
        result.menu = state.menu
          .filter((o) => (o.cat_id === i.id))
        return Object.assign(i, result);
      })
    },

    confirmItem: (state, id) => {
      state.cart.find((item) => item.id === id).confirmed = true
    },
    addOption: (state, params) => {
      if (params.checked) {
        state.cart.find((item) => item.id === params.itemId)
          .selected_options.push(params.option)
      } else {
        const optionIndex = state.cart.find((item) => item.id === params.itemId)
          .selected_options.findIndex((i) => i.id === params.option.id)
        state.cart.find((item) => item.id === params.itemId)
          .selected_options.splice(optionIndex, 1)
      }
    },
    addSauce: (state, params) => {
      state.cart.find((item) => item.id
        === params.itemId).selected_sauce = params.sauce
    },
    removeAll: (state) => {
      state.cart = []
    },
    resetState: (state) => {
      state.restaurant = null
      state.cart = []
      state.currentCategory = null
      state.categories = []
      state.menu = []
      state.partners = []
      state.isLoading = false
    },
    setPartners: (state, list) => {
      state.partners = list
      state.isLoading = false
    }
  },
})
