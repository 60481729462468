<template>
  <div class="plug">
    <div class="plug__wrapper">
      <div class="qr-icon">
        <img
          src="../assets/img/logo-head-white.svg"
          alt="logo"
        >
      </div>
      <div class="plug__text-info">
        Для корректной работы <br> воспользуйтесь Смартфоном.
      </div>
      <div class="plug__text-instruction">
        Отсканируйте QR-код
      </div>
      <canvas
        id="canvas"
        class="qr"
      >
      </canvas>
      <div class="plug__text-instruction">
        Или перейдите по указанной ссылке: {{ `https://mqr.kz${this.$route.fullPath}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Plug',
  mounted() {
    const url = `https://mqr.kz${this.$route.fullPath}`
    const options = {
      scale: 7
    }
    /* eslint-disable */
    const QRCode = require('qrcode')
    const canvas = document.getElementById('canvas')
    QRCode.toCanvas(canvas, url, options, (error) => {
      if (error) console.error(error)
    })
  }
}
</script>

<style scoped lang="scss">
  .qr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .plug__link {
    color: white;
  }
</style>
