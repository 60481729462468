<template>
  <div class="wrap">
    <BackHeader
      v-if="item"
      :item-id="$route.query.id"
    />
    <div class="item-info">
      <div class="item-info__head">
        <div class="item-info__head--icon">
          <img
            v-if="item.photo"
            class="desktop-menu-item-img"
            :src="logoUrl + item.photo"
          >
          <img
            v-else
            src="@/assets/img/no-menu-item.png"
            class="desktop-menu-item-img"
          >
        </div>
        <div class="item-info__head--details">
          <span class="item-info__head--details__title">
            {{ item.name }}
          </span>
          <span
            v-if="item.ingredient"
            class="item-info__head--details__ingredients"
          >
            {{ item.ingredient }}
          </span>
          <span class="item-info__head--details__control">
            <template v-if="cartItem">
              <div
                class="item-info__head--details--control--add"
                @click="addToCart('-')"
              >
                <span class="item-info__head--details--control--add__icon">
                  -
                </span>
              </div>
              <div class="item-info__head--details--control--amount">
                {{ cartItem.quantity }}
              </div>
            </template>
            <div
              class="item-info__head--details--control--add"
              @click="addToCart('+')"
            >
              <span class="item-info__head--details--control--add__icon">
                +
              </span>
            </div>
            <span class="item-info__head--details--control--price">
              {{ countPrice() }} {{ item.currency }}
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="item.rel_options.length"
        class="item-info__additional"
      >
        <div
          v-if="sauces.length > 0 && cartItem"
          class="item-info__additional--item"
        >
          <div class="menu-item--additional__section-title">
            <span class="item-info__additional--item__title">
              Выберите соус
            </span>
            <button
              class="item-info__additional--item__button"
              @click="showSauces = true"
            >
              <span class="item-info__additional--item__button__text">
                Выбрать соус
              </span>
            </button>
          </div>
          <ul
            v-if="showSauces
              || (cartItem && cartItem.selected_sauce)"
            class="item-info__additional--item__list"
          >
            <li
              v-for="sauce in sauces"
              :key="'s' + sauce.id"
              class="item-info__additional--item__list__el"
            >
              <label class="item-info__additional--item__list__el--text">
                <input
                  class="item-info__additional--item__list__radio"
                  type="radio"
                  :name="`${item.id}_sauce`"
                  :checked="checkSauceChoosed(sauce.id)"
                  @change="chooseSauce(sauce)"
                >
                <span> {{ sauce.name }} </span>
              </label>
            </li>
          </ul>
        </div>
        <div
          v-if="options && options.length > 0
            && cartItem"
          class="item-info__additional--item"
        >
          <div class="menu-item--additional__section-title">
            <span class="item-info__additional--item__title">
              Добавки
            </span>
            <button
              class="item-info__additional--item__button"
              @click="showOptions = true"
            >
              <span class="item-info__additional--item__button__text">
                Выбрать добавки
              </span>
            </button>
          </div>
          <ul
            v-if="showOptions
              || (cartItem && cartItem.selected_options.length > 0)"
            class="item-info__additional--item__list"
          >
            <li
              v-for="option in options"
              :key="option.id"
              class="item-info__additional--item__list__el"
            >
              <label class="item-info__additional--item__list__el--text">
                <input
                  class="item-info__additional--item__list__checkbox"
                  type="checkbox"
                  :checked="checkOptionChoosed(option.id)"
                  @change="addOption(option, $event)"
                >
                <span> {{ option.name }} </span>
                <span class="item-info__additional--item__list__el--price">
                  + {{ option.price }}{{ item.currency }}
                </span>
              </label>
            </li>
          </ul>
        </div>
        <button
          v-if="(cartItem && cartItem.confirmed) || newCartItem"
          class="item-info-add-item-btn"
          :class="{'submit-confirm': (cartItem && cartItem.confirmed)}"
          @click="confirmItem"
        >
          <span class="item-info-add-item-btn__text">
            Добавить в заказ
          </span>
        </button>
      </div>
      <div
        v-if="description"
        class="item-info__description"
      >
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'
import BackHeader from '@/components/BackHeader.vue';

export default {
  name: 'Item',
  components: {
    BackHeader,
  },
  mixins: [api],
  data: () => ({
    item: null,
    showSauces: false,
    showOptions: false,
    newCartItem: false
  }),
  computed: {
    ...mapGetters([
      'fromCart',
      'cart'
    ]),
    cartItem() {
      return this.fromCart(this.$route.query.id)
    },
    description() {
      return this.item.note
        ? this.item.note.replace(/(<([^>]+)>)/gi, '') : '';
    },
    sauces() {
      return this.item.rel_options.filter((o) => o.sous === 'yes')
    },
    options() {
      return this.item.rel_options.filter((o) => o.sous === 'no')
    }
  },
  /* eslint-disable */
  watch: {
    '$route.fullPath'() {
      this.item = this.$route.params.item
    }
  },
  mounted() {
    this.item = this.$route.params.item
  },
  methods: {
    countPrice() {
      return this.item.price * (this.cartItem ? this.cartItem.quantity : 1)
    },
    addToCart(add) {
      if (!this.cartItem) {
        this.$store.dispatch('addItemToCart', this.item)
        this.newCartItem = true
      } else {
        this.$store.commit(add === '+'
          ? 'increaseCartItem' : 'decreaseCartItem', this.item.id)
      }
      if (!this.isMobile) {
        this.$store.commit('confirmItem', this.item.id)
      }
    },
    confirmItem() {
      if (!this.cartItem) {
        this.$store.dispatch('addItemToCart', this.item)
      }
      this.$store.commit('confirmItem', this.item.id)
    },
    addOption(option, e) {
      const params = {
        option,
        checked: e.target.checked,
        itemId: this.item.id
      }
      this.$store.commit('addOption', params)
    },
    checkOptionChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_options.find((o) => o.id === id)
    },
    chooseSauce(sauce) {
      const params = {
        sauce,
        itemId: this.item.id
      }
      this.$store.commit('addSauce', params)
    },
    checkSauceChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_sauce
        && this.cartItem.selected_sauce.id === id
    }
  }
}
</script>
