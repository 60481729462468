<template>
  <div
    :id="`item-${item.id}`"
    :class="{'menu-item-ordered': cartItem && $route.name !== 'Cart'}"
    class="restaurant-body__menu-category__list--item"
  >
    <div class="restaurant-body__menu-category__list--item--main">
      <div class="menu-item-details">
        <router-link
          class="menu-item--link"
          :to="{name: !isMobile ? 'Item' : '',
                query: !isMobile ? {id: item.id} : {},
                params: !isMobile ? {item: item} : {}}"
        >
          <span
            class="restaurant-body__menu-category__list--item--main--title"
          >
            {{ item.name }}
          </span>
          <span
            v-if="isMobile && (item.note || item.ingredient)"
            class="restaurant-body__menu-category__list--item--main--more"
            @click="$emit('modal_details_opened', item)"
          >
            Подробнее
          </span>
        </router-link>
        <div class="restaurant-body__menu-category__list--item--main--cont">
          <template v-if="cartItem">
            <div
              class="item--main--cont-add"
              @click="addToCart(item, '-')"
            >
              -
            </div>
            <span class="item--main--cont-amount">
              {{ cartItem.quantity }}
            </span>
          </template>
          <div
            class="item--main--cont-add"
            @click="addToCart(item, '+')"
          >
            +
          </div>
          <div class="menu-item-prices">
            <span
              v-if="item.discounted_price"
              class="item--main--cont-price item--main-greencolor"
            >
              {{ countPrice(item.discounted_price) }} {{ item.currency }}
            </span>
            <span
              :class="{'item--main-prices-line': item.discounted_price}"
              class="item--main--cont-price"
            >
              {{ countPrice(item.price) }} {{ item.currency }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="restaurant-body__menu-category__list--item--main--icon"
      >
        <img
          v-if="item.photo_128"
          v-lazy="logoUrl + item.photo_128"
          class="menu-item-img"
          @click="$emit('modal_opened', item)"
        >
        <img
          v-else
          src="@/assets/img/no-menu-item.png"
          class="menu-item-img nf-img"
        >
      </div>
    </div>
    <div
      v-if="item.rel_options.length > 0 && cartItem && $route.name !== 'Cart'"
      class="restaurant-body__menu-category__list--item--additional"
    >
      <div
        v-if="sauces.length > 0"
        class="menu-item--additional__section"
      >
        <div class="menu-item--additional__section-title">
          <span class="item-info__additional--item__title">
            Выберите соус
          </span>
          <button
            class="item-info__additional--item__button"
            @click="showSauces = true"
          >
            Выбрать соус
          </button>
        </div>
        <ul
          v-if="showSauces
            || (cartItem && cartItem.selected_sauce)"
          class="item-info__additional--item__list"
        >
          <li
            v-for="sauce in sauces"
            :key="'s' + sauce.id"
            class="item-info__additional--item__list__el"
          >
            <label class="item-info__additional--item__list__el--text">
              <input
                class="item-info__additional--item__list__radio"
                type="radio"
                :name="`${item.id}_sauce`"
                :checked="checkSauceChoosed(sauce.id)"
                @change="chooseSauce(sauce)"
              >
              <span> {{ sauce.name }} </span>
            </label>
          </li>
        </ul>
      </div>
      <div
        v-if="options.length > 0"
        class="menu-item--additional__section"
      >
        <div class="menu-item--additional__section-title">
          <span class="item-info__additional--item__title">
            Добавки
          </span>
          <button
            class="item-info__additional--item__button"
            @click="showOptions = true"
          >
            Выбрать добавки
          </button>
        </div>
        <ul
          v-if="showOptions
            || (cartItem && cartItem.selected_options.length > 0)"
          class="item-info__additional--item__list"
        >
          <li
            v-for="option in options"
            :key="'o' + option.id"
            class="item-info__additional--item__list__el"
          >
            <label class="item-info__additional--item__list__el--text">
              <input
                class="item-info__additional--item__list__checkbox"
                type="checkbox"
                :checked="checkOptionChoosed(option.id)"
                @change="addOption(option, $event)"
              >
              <span> {{ option.name }} </span>
              <span class="item-info__additional--item__list__el--price">
                + {{ option.price }}{{ item.currency }}
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="cartItem && $route.name !== 'Cart' && !isTablet"
      class="restaurant-body__menu-category__list--item--footer"
    >
      <button
        class="menu-item__footer--submit"
        :class="{'submit-confirm': cartItem.confirmed}"
        @click="confirmItem(item.id)"
      >
        Добавить в заказ
      </button>
    </div>
    <div
      v-if="item.rel_options.length > 0 && cartItem && $route.name !== 'Cart'"
      class="restaurant-body__menu-category__list--item__same"
      @click="$emit('sameAdded', item, Math.floor(Math.random() * 100))"
    >
      <span>
        +
      </span>
      <span class="item-same-text">
        Добавить такое же блюдо с другими опциями
      </span>
    </div>
    <div
      v-if="cartItem && (cartItem.selected_options.length > 0
        || cartItem.selected_sauce)
        && $route.name === 'Cart'"
      class="restaurant-body__menu-category__list--item__selected-options"
    >
      <template v-if="cartItem.selected_options.length > 0">
        <div
          v-for="option in cartItem.selected_options"
          :key="'selected_' + option.id"
          class="selected-options__item"
        >
          {{ option.name }}
          <span class="selected-options__item--price">
            + {{ option.price }}{{ item.currency }}
          </span>
        </div>
      </template>
      <template v-if="cartItem.selected_sauce">
        <div class="selected-options__item">
          {{ cartItem.selected_sauce.name }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api';

export default {
  name: 'MenuItem',
  mixins: [api],
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    showOptions: false,
    showSauces: false
  }),
  computed: {
    ...mapGetters([
      'fromCart'
    ]),
    cartItem() {
      return this.fromCart(this.item.id)
    },
    sauces() {
      return this.item.rel_options.filter((o) => o.sous === 'yes')
    },
    options() {
      return this.item.rel_options.filter((o) => o.sous === 'no')
    }
  },
  methods: {
    addToCart(item, add) {
      if (!this.cartItem) {
        this.$store.dispatch('addItemToCart', item)
      } else {
        this.$store.commit(add === '+'
          ? 'increaseCartItem' : 'decreaseCartItem', item.id)
      }
      if (this.isTablet) {
        this.confirmItem(item.id)
      }
    },
    countPrice(price) {
      return price * (this.cartItem ? this.cartItem.quantity : 1)
    },
    confirmItem(id) {
      this.$store.commit('confirmItem', id)
    },
    addOption(option, e) {
      const params = {
        option,
        checked: e.target.checked,
        itemId: this.item.id
      }
      this.$store.commit('addOption', params)
    },
    checkOptionChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_options.find((o) => o.id === id)
    },
    chooseSauce(sauce) {
      const params = {
        sauce,
        itemId: this.item.id
      }
      this.$store.commit('addSauce', params)
    },
    checkSauceChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_sauce
        && this.cartItem.selected_sauce.id === id
    }
  },
}
</script>
