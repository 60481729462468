<template>
  <transition name="modal-fade">
    <div
      :class="{'modal-promotions': promotion === true}"
      class="menu-item-modal modal-backdrop"
      @click.self="closeProm"
    >
      <div
        class="menu-item-modal-bd modal"
        @click.self="close"
      >
        <section
          class="modal-body photo"
        >
          <img
            class="menu-item-img modal-img"
            :src="image"
          >
          <span class="overlayed"></span>
        </section>
        <section
          v-if="promotion"
          class="modal-body-text"
        >
          <section class="modal-body-title">
            {{ name }}
          </section>
          <section class="modal-body-description">
            {{ description }}
          </section>
          <button
            class="promotions__button promotions__button-modal"
            @click="close"
          >
            Ок
          </button>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>

import api from '@/mixins/api'

export default {
  name: 'Modal',
  mixins: [api],
  props: {
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    ingredients: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    promotion: {
      type: Boolean
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },
    closeProm() {
      if (!this.promotion) {
        this.$emit('close');
      }
    }
  },
};
</script>
