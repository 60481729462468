import Vue from "vue";
import VueLazyload from "vue-lazyload";
import "@/assets/css/main.scss";
import "@/assets/css/style.scss";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import VueMask from "v-mask";
import VueScrollactive from "vue-scrollactive";
import VTooltip from "v-tooltip";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
/* eslint-disable */
import sentry from "@/plugins/sentry";
import metrika from "@/plugins/metrika";

Vue.use(VueMask);
Vue.use(VueScrollactive);
Vue.use(VTooltip);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  loading: require("@/assets/img/no-menu-item.png"),
  listenEvents: ["scroll"]
});

Vue.config.productionTip = false;

new Vue({
  name: "EMenu",
  router,
  store,
  render: h => h(App)
}).$mount("#app");
