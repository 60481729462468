<template>
  <scrollactive
    active-class="item-active"
    :duration="1"
    :offset="offset"
    :scroll-offset="offset - 10"
    :click-to-scroll="true"
    :highlight-first-item="true"
    :modify-url="false"
    :always-track="true"
    @itemchanged="scrollInSlide"
  >
    <a
      v-if="isMobile && restaurant.rel_banners.length"
      :href="`#category-promotions`"
      class="scrollactive-item slide-menu__list-item"
    >
      Акции
    </a>
    <a
      v-for="category in menuCat"
      :key="category.id"
      :href="`#category-${category.id}`"
      class="scrollactive-item slide-menu__list-item"
    >
      {{ category.name }}
    </a>
  </scrollactive>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
import api from '@/mixins/api'

export default {
  name: 'SlideMenu',
  mixins: [api],
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    currentCategory: {
      type: Object,
      default: null
    },
    offset: {
      type: Number,
      default: 50
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant'
    }),
    menuCat() {
      return this.categories.filter((c) => c.menu.length)
    }
  },
  methods: {
    scrollInSlide(e, currentItem) {
      if (this.isMobile) {
        $('#slide-nav').animate({
          scrollLeft: currentItem.offsetLeft - 70
        }, 100)
      } else {
        $('#nav').animate({ scrollLeft: currentItem.offsetLeft - 290 }, 100)
      }
    }
  }
}
</script>
