<template>
  <div class="header-top">
    <router-link
      :to="{name: 'Landing'}"
      class="logo"
    >
      <img
        src="@/assets/img/logo-head.svg"
        class="logo-img"
      />
    </router-link>
    <div
      v-if="logo"
      class="res-icon"
    >
      <img
        @error="logoError"
        v-if="!isTablet"
        class="item-info__head--icon__img"
        :src="`${logoUrl}${restaurant.logo}`"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'

export default {
  name: 'HeaderTop',
  data() {
    return {
      logo: true
    }
  },
  mixins: [api],
  computed: {
    ...mapGetters({
      restaurant: 'restaurant'
    })
  },
  methods:{
    logoError() {
      this.logo = false
    }
  }
}
</script>
