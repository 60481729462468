<template>
  <transition name="modal-fade">
    <div
      class="modal-backdrop"
      @click="$emit('closed')"
    >
      <div class="restaurant-body__menu-category__list--item-detail">
        <div class="detail-head">
          <div class="detail-img">
            <img
              v-if="details.photo"
              :src="`${logoUrl}${details.photo}`"
            >
            <img
              v-else
              src="@/assets/img/no-menu-item.png"
            >
          </div>
          <div class="detail-title">
            {{ details.name }}
          </div>
          <div
            class="detail-close-btn"
            @click="$emit('closed')"
          >
          </div>
        </div>
        <div class="detail-body">
          <div
            v-if="details.ingredient"
            class="detail-ing"
          >
            <div class="detail-body-title">
              Ингредиенты
            </div>
            <div class="detail-body-text">
              {{ details.ingredient }}
            </div>
          </div>
          <div
            v-if="details.note"
            class="detail-desc"
          >
            <div class="detail-body-title">
              Описание блюда
            </div>
            <!--eslint-disable-->
            <div class="detail-body-text" v-html="details.note" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/mixins/api'

export default {
  name: 'MenuItemDetails',
  mixins: [api],
  props: {
    details: {
      type: Object,
      default: null
    }
  },
}
</script>
