<template>
  <div class="restaurant-head__details">
    <div class="restaurant-head__info">
      <v-popover
        offset="16"
        :contacts="contacts"
      >
        <div
          class="restaurant-head__info__item"
        >
          <span
            class="restaurant-head__info__item--icon__contact"
          />
          <span class="restaurant-contacts restaurant-head__info__item--text">
            Контакты
          </span>
        </div>
        <template
          #popover
        >
          <TooltipBoxContacts
            :contacts="contacts"
          />
        </template>
      </v-popover>
      <v-popover
        offset="16"
        :schedule="timetable"
      >
        <div
          class="item-schedule restaurant-head__info__item"
        >
          <span
            class="restaurant-head__info__item--icon__clock"
          />
          <span class="restaurant-head__info__item--text restaurant-schedule">
            Режим работы
          </span>
        </div>
        <template
          #popover
        >
          <TooltipBoxSchedule
            :schedule="timetable"
          />
        </template>
      </v-popover>
    </div>
    <div
      v-if="contacts"
      class="restaurant-head__links"
    >
      <a
        v-if="contacts.insta"
        class="restaurant-head__links__item"
        :href="contacts.insta"
        target="_blank"
      >
        <img
          class="restaurant-head__links__item__img"
          src="@/assets/img/instagram.png"
        >
      </a>
      <a
        v-if="contacts.facebook"
        class="restaurant-head__links__item"
        :href="contacts.facebook"
        target="_blank"
      >
        <img
          class="restaurant-head__links__item__img"
          src="@/assets/img/facebook.svg"
        >
      </a>
      <a
        v-if="contacts.vk"
        class="restaurant-head__links__item"
        :href="contacts.vk"
        target="_blank"
      >
        <img
          class="restaurant-head__links__item__img"
          src="@/assets/img/vk.svg"
        >
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'
import TooltipBoxSchedule from '@/components/desktop/TooltipBoxSchedule.vue'
import TooltipBoxContacts from '@/components/desktop/TooltipBoxContacts.vue'

export default {
  name: 'HeadDetails',
  components: {
    TooltipBoxSchedule,
    TooltipBoxContacts
  },
  mixins: [api],
  computed: {
    ...mapGetters({
      contacts: 'contacts',
      timetable: 'timetable',
    })
  },
}
</script>
