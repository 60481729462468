<template>
  <section
    v-if="isMobile"
    id="category-promotions"
    class="promotions__section"
  >
    <div class="restaurant-body__menu-category-title">
      <span class="restaurant-body__menu-category-title__text">
        &#9733; Акции
      </span>
    </div>
    <div class="promotions__wrap">
      <div
        v-for="(banner, index) in restaurant.rel_banners"
        :key="`banner-${index}`"
        class="promotions__slide"
      >
        <div
          class="promotions__bg"
          @click="$emit('modal_opened', banner)"
        >
        </div>
        <img
          :src="`${logo}${banner.image}`"
          alt="prom-img"
          class="promotions__photo"
        >
        <div
          class="promotions__text"
          @click="$emit('modal_opened', banner)"
        >
          <div
            class="promotions__title
            restaurant-body__menu-category__list--item--main--title"
          >
            {{ banner.name }}
          </div>
          <div
            class="promotions__descrp
            restaurant-body__menu-category__list--item--main--title"
          >
            {{ banner.description }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
import api from '@/mixins/api'

export default {
  name: 'Banners',
  mixins: [api],
  props: {
    logo: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant'
    })
  },
  mounted() {
    if (this.restaurant.rel_banners.length) {
      $('.promotions__wrap').slick({
        dots: false,
        infinite: true,
        speed: 300,
        autoplay: false,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        centerMode: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          }]
      });
    }
  },
  methods: {
    goTo(banner) {
      if (banner.rel_menu) {
        return `#item-${banner.rel_menu.id}`
      }
      return `#category-${banner.rel_menu_cat.id}`
    }
  }
}
</script>
