<template>
  <div class="sticky-head">
    <div
      v-if="isMobile"
      class="restaurant-head__search"
      :class="{'active': isSearch}"
    >
      <div
        class="restaurant-head__search__wrap"
        :class="{'active': isSearch}"
      >
        <img
          class="restaurant-head__search__wrap__icon"
          src="@/assets/img/icons/search.png"
          @click="toggleSearch"
        />
        <input
          v-model="searchText"
          placeholder="Поиск по блюдам"
          @keyup.enter="$emit('searched', searchText)"
        >
        <img
          class="restaurant-head__search__wrap__cross"
          src="@/assets/img/icons/cross-grey.png"
          @click="clearSearch"
        />
      </div>
    </div>
    <div
      id="slide-nav"
      class="slide-menu__list"
      :class="{'active': isSearch}"
    >
      <SlideMenu
        id="nav"
        :categories="categories"
        :current-category="currentCategory"
        :offset="offset"
      />
      <CartDesktop
        v-if="!isMobile"
        class="desktop-cart-button"
      />
    </div>
    <CartButton
      v-if="isTablet"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SlideMenu from '@/components/SlideMenu.vue'
import CartDesktop from '@/components/desktop/CartDesktop.vue'
import CartButton from '@/components/mobile/CartButton.vue'
import api from '@/mixins/api'

export default {
  name: 'FilterHead',
  components: {
    SlideMenu,
    CartDesktop,
    CartButton
  },
  mixins: [api],
  props: {
    offset: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchText: '',
      isSearch: false
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories',
      currentCategory: 'currentCategory',
    })
  },
  methods: {
    toggleSearch() {
      if (this.isSearch) {
        this.$emit('searched', this.searchText)
      } else {
        this.isSearch = true
      }
    },
    clearSearch() {
      this.searchText = ''
      this.isSearch = false
    }
  }
}
</script>
