<template>
  <transition name="modal-fade">
    <div
      class="modal-backdrop"
      @click="$emit('closed')"
    >
      <div class="no-results">
        Ничего не найдено
      </div>
    </div>
  </transition>
</template>

<script>
import api from '@/mixins/api'

export default {
  name: 'NoResults',
  mixins: [api],
}
</script>

<style lang="scss">
  .no-results {
    position: fixed;
    padding: 20px 15px;
    border-radius: 6px;
    background: #fff;
    z-index: 99;
    transition: 0.1s ease;
    box-shadow: 0px -4px 11px 2px rgb(0 0 0 / 25%);
}
</style>
