<template>
  <div>
    <span class="modal-body__title tooltip-item">
      Контакты
    </span>
    <template>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Номер телефона
        </span>
        <span class="modal-body__item--info">
          {{ contacts.phone }}
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Адрес
        </span>
        <span class="modal-body__item--info">
          {{ contacts.address }}
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          e-mail
        </span>
        <span class="modal-body__item--info">
          {{ contacts.work_email }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TooltipBoxContacts',
  props: {
    contacts: {
      type: Object,
      default: null
    }
  }
}
</script>
