<template>
  <div>
    <router-view v-if="$route.name === 'Landing'" />
    <Plug v-else />
  </div>
</template>

<script>
import Plug from '@/views/Plug.vue'

export default {
  name: 'DesktopView',
  components: {
    Plug
  }
}
</script>
