<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close">
      <div class="modal-qlt">
        <section class="error404">
          <div class="blueGradient"></div>
          <div class="stars"></div>
          <div class="planet">
            <div class="planetImg">
              <div class="spaceship">
                <img src="@/assets/img/landing/qlt/404spaceship.png" alt="" />
              </div>
              <img src="@/assets/img/landing/qlt/404planet.png" alt="" />
            </div>
          </div>
          <div class="spaceMan">
            <img src="@/assets/img/landing/qlt/404spaceman.png" alt="" />
          </div>
          <div class="comet">
            <img src="@/assets/img/landing/qlt/404comet.png" alt="" />
          </div>
          <div class="eror404Content">
            <div class="error404Img">
              <img src="@/assets/img/landing/qlt-logo.png" alt="" />
            </div>
            <div class="eror404ups">
              IT решения в любой сфере
            </div>
            <a href="https://qlt.kz/" class="visitSite" target="_blank">
              Перейти на сайт
            </a>
          </div>
          <!-- <div class="timeline">
            <div class="timeline-fill"></div>
          </div> -->
          <span class="modal-tarif-choose__body--close close" @click="close"> </span>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "QltModal",
  props: {},
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  display: block;
}
.modal-qlt {
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #000;
}
.error404 {
  background: #000;
  height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.stars {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url("~@/assets/img/landing/qlt/404stars.png") no-repeat center/cover;
  transition: 1.5s ease;
  animation: starsAnimation 1.5s forwards;
}

@keyframes starsAnimation {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.blueGradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(360deg, #074d7c 9.64%, #000a10 100%);
  opacity: 0;
}

.planet {
  position: absolute;
  left: 0;
  right: 0;
  animation: planetAnimation 1.5s forwards;
}

@keyframes planetAnimation {
  0% {
    bottom: -50%;
  }
  100% {
    bottom: -10px;
  }
}

.planetImg {
  position: relative;

  & > img {
    width: 100%;
    position: relative;
  }
}

.spaceMan {
  position: absolute;
  top: 5%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 1.5s ease;
  animation: spaceManAnimation 1.5s forwards;
}

@keyframes spaceManAnimation {
  0% {
    transform: rotate(-180deg);
    left: -50%;
  }
  100% {
    transform: rotate(0deg);
    left: 8%;
  }
}

.comet {
  position: absolute;
  animation: cometAnimation 1.5s forwards;
}

@keyframes cometAnimation {
  0% {
    right: -20%;
    bottom: 60%;
  }
  100% {
    right: 0;
    bottom: 50%;
  }
}

.spaceship {
  position: absolute;
  top: 0;
  animation: spaceshipAnimation 1.5s forwards;
}

@keyframes spaceshipAnimation {
  0% {
    right: 30%;
  }
  100% {
    right: 10%;
  }
}

.eror404Content {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 460px;
  text-align: center;
  animation: contentAnimation 1.5s forwards;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }
}

@keyframes contentAnimation {
  0% {
    bottom: 100%;
  }
  100% {
    bottom: 40%;
  }
}

.eror404ups {
  font-weight: 700;
  font-size: 30px;
  color: #fff;
}

.eror404notFound {
  font-weight: 700;
}

.eror404BackToHome {
  display: inline-block;
  margin: 30px auto 0;
  border: 1px solid #fff;
  padding: 24px 65px;
  color: #fff;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 0;
    height: 0;
    transition: 0.3s ease;
    background: #fff;
  }

  &:hover {
    color: black;

    &:after {
      width: 500px;
      height: 500px;
    }
  }
}

.timeline {
  position: absolute;
  left: 1%;
  right: 1%;
  top: 1%;
  height: 3px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.35);
  .timeline-fill {
    height: 100%;
    background: #fff;
    border-radius: 10px;
    animation: timelineAnimation 5s forwards;
    animation-timing-function: linear;
  }
}

@keyframes timelineAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.visitSite {
  display: block;
  background: #fff;
  padding: 15px 30px;
  color: #000;
  border-radius: 100px;
  cursor: pointer;
  font-family: Montserrat;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s ease;
  margin-top: 20px;
  -webkit-box-shadow: 0px 5px 17px -1px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 5px 17px -1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 5px 17px -1px rgba(0, 0, 0, 0.37);
  &:hover {
    -webkit-box-shadow: 0px 5px 17px 7px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 0px 5px 17px 7px rgba(0, 0, 0, 0.39);
    box-shadow: 0px 5px 17px 7px rgba(0, 0, 0, 0.39);
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  background: url("~@/assets/img/modal_cross.svg") no-repeat;
}

@media screen and (max-width: 1399px) {
  .spaceMan {
    img {
      width: 400px;
    }
  }
  @keyframes spaceManAnimation {
    0% {
      transform: rotate(-180deg);
      left: -50%;
    }
    100% {
      transform: rotate(0deg);
      left: -5%;
    }
  }
  .comet {
    width: 200px;
  }
  @keyframes cometAnimation {
    0% {
      right: -20%;
    }
    100% {
      right: -8%;
    }
  }
  @keyframes spaceshipAnimation {
    0% {
      right: 30%;
    }
    100% {
      right: 6%;
    }
  }
  @keyframes contentAnimation {
    0% {
      bottom: 100%;
    }
    100% {
      bottom: 35%;
    }
  }
}

@media screen and (max-width: 1099px) {
  .eror404Content {
    width: 360px;
  }
  @keyframes contentAnimation {
    0% {
      bottom: 100%;
    }
    100% {
      bottom: 35%;
    }
  }
  .eror404ups {
    font-size: 20px;
  }
  @keyframes spaceshipAnimation {
    0% {
      right: 30%;
    }
    100% {
      right: 1%;
    }
  }
}

@media screen and (max-width: 800px) {
  .planet {
    height: 50%;
    .planetImg {
      display: flex;
      justify-content: center;
      & > img {
        width: auto;
      }
    }
  }
  .spaceMan {
    top: 25%;
    img {
      width: 250px;
    }
  }
  .spaceship {
    top: -10%;
    transform: rotate(-25deg);
  }
}

@media screen and (max-width: 600px) {
  .error404Img {
    width: 160px;
  }
  .eror404Content {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes contentAnimation {
    0% {
      bottom: 100%;
    }
    100% {
      bottom: 40%;
    }
  }
  .comet {
    width: 150px;
    right: 0;
    img {
      width: 100%;
    }
  }
  @keyframes cometAnimation {
    0% {
      right: -50%;
    }
    100% {
      right: 0;
    }
  }
  .spaceMan {
    top: 50%;
  }
  .planet {
    .planetImg {
      .spaceship {
        img {
          width: 130px;
        }
      }
    }
  }
  .spaceship {
    top: 10px;
  }
  .visitSite {
    width: 160px;
    text-align: center;
  }
  .spaceMan {
    img {
      width: 200px;
    }
  }
  @keyframes spaceManAnimation {
    0% {
      transform: rotate(-180deg);
      left: -50%;
    }
    100% {
      transform: rotate(0deg);
      left: -15%;
    }
  }
  .error404ups {
    font-size: 18px;
  }
}
</style>
