<template>
  <div class="desktop-category__list--item">
    <div class="desktop-category__list--item--main">
      <div class="desktop-menu-item-details">
        <router-link
          class="menu-item--link"
          :to="{name: 'Item',
                query: {id: item.id},
                params: {item: item}}"
        >
          <span
            class="restaurant-body__menu-category__list--item--main--title"
          >
            {{ item.name }}
          </span>
          <span
            v-if="item.ingredient"
            class="restaurant-body__menu-category__list--item--main--ing"
          >
            {{ item.ingredient }}
          </span>
        </router-link>
        <div class="restaurant-body__menu-category__list--item--main--cont">
          <template v-if="cartItem">
            <div
              class="desktop-add item--main--cont-add"
              @click="addToCart(item, '-')"
            >
              -
            </div>
            <span class="item--main--cont-amount">
              {{ cartItem.quantity }}
            </span>
          </template>
          <div
            class="desktop-add item--main--cont-add"
            @click="addToCart(item, '+')"
          >
            +
          </div>
          <div class="menu-item-prices">
            <span class="item--main--cont-price">
              {{ countPrice() }} {{ item.currency }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="desktop-category__list--item--main--icon"
      >
        <img
          v-if="item.photo_128"
          :src="logoUrl + item.photo_128"
          class="desktop-menu-item-img"
          @click="showModal(item)"
        >
        <img
          v-else
          src="@/assets/img/no-menu-item.png"
          class="desktop-menu-item-img"
        >
      </div>
    </div>
    <div
      v-if="item.rel_options.length > 0"
      class="restaurant-body__menu-category__list--item--additional-desktop"
    >
      <div
        v-if="sauces.length > 0"
        class="menu-item--additional__section"
      >
        <div class="menu-item--additional__section-title">
          <span class="item-info__additional--item__title">
            Выберите соус
          </span>
          <button
            class="item-info__additional--item__button"
            @click="showSauces = true"
          >
            Выбрать соус
          </button>
        </div>
        <ul
          v-if="showSauces
            || (cartItem && cartItem.selected_sauce)"
          class="item-info__additional--item__list"
        >
          <li
            v-for="sauce in sauces"
            :key="'s' + sauce.id"
            class="item-info__additional--item__list__el"
          >
            <label class="item-info__additional--item__list__el--text">
              <input
                class="item-info__additional--item__list__radio"
                type="radio"
                :name="`${cartItem.id}-sauce`"
                :checked="checkSauceChoosed(sauce.id)"
                @change="chooseSauce(sauce)"
              >
              <span> {{ sauce.name }} </span>
            </label>
          </li>
        </ul>
      </div>
      <div
        v-if="options.length > 0"
        class="menu-item--additional__section"
      >
        <div class="menu-item--additional__section-title">
          <span class="item-info__additional--item__title">
            Добавки
          </span>
          <button
            class="item-info__additional--item__button"
            @click="showOptions = true"
          >
            Выбрать добавки
          </button>
        </div>
        <ul
          v-if="showOptions
            || (cartItem && cartItem.selected_options.length > 0)"
          class="item-info__additional--item__list"
        >
          <li
            v-for="option in options"
            :key="'o' + option.id"
            class="item-info__additional--item__list__el"
          >
            <label class="item-info__additional--item__list__el--text">
              <input
                class="item-info__additional--item__list__checkbox"
                type="checkbox"
                :checked="checkOptionChoosed(option.id)"
                @change="addOption(option, $event)"
              >
              <span> {{ option.name }} </span>
              <span class="item-info__additional--item__list__el--price">
                + {{ option.price }}{{ item.currency }}
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="item.rel_options.length > 0"
      class="d-restaurant-body__menu-category__list--item__same"
      @click="$emit('sameAdded', item, Math.floor(Math.random() * 100))"
    >
      <span>
        +
      </span>
      <span class="item-same-text">
        Добавить такое же блюдо с другими опциями
      </span>
    </div>
    <div
      v-if="cartItem && (cartItem.selected_options.length > 0
        || cartItem.selected_sauce)"
      class="d-restaurant-body__menu-category__list--item__selected-options"
    >
      <template v-if="cartItem.selected_options.length > 0">
        <div
          v-for="option in cartItem.selected_options"
          :key="'selected_' + option.id"
          class="selected-options__item"
        >
          {{ option.name }}
          <span class="selected-options__item--price">
            + {{ option.price }}{{ item.currency }}
          </span>
        </div>
      </template>
      <template v-if="cartItem.selected_sauce">
        <div class="selected-options__item">
          {{ cartItem.selected_sauce.name }}
        </div>
      </template>
    </div>
    <MenuItemModal
      v-show="isModalVisible"
      :image="itemLogo"
      :name="itemName"
      :ingredients="itemIngredients"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/mixins/api'
import MenuItemModal from '@/components/modals/MenuItemModal.vue'

export default {
  name: 'MenuItemDesktop',
  components: {
    MenuItemModal
  },
  mixins: [api],
  props: {
    item: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    isModalVisible: false,
    itemLogo: '',
    itemName: '',
    itemIngredients: '',
    showOptions: false,
    showSauces: false
  }),
  computed: {
    ...mapGetters([
      'fromCart'
    ]),
    cartItem() {
      return this.fromCart(this.item.id)
    },
    sauces() {
      return this.item.rel_options.filter((o) => o.sous === 'yes')
    },
    options() {
      return this.item.rel_options.filter((o) => o.sous === 'no')
    }
  },
  methods: {
    showModal(val) {
      this.itemLogo = this.logoUrl + val.photo
      this.itemName = val.name
      this.itemIngredients = val.ingredient
      this.isModalVisible = true;
      document.body.style.overflowY = 'hidden';
    },
    closeModal() {
      this.isModalVisible = false;
    },
    addToCart(item, add) {
      if (!this.cartItem) {
        this.$store.dispatch('addItemToCart', item)
      } else {
        this.$store.commit(add === '+'
          ? 'increaseCartItem' : 'decreaseCartItem', item.id)
      }
    },
    countPrice() {
      return this.item.price * (this.cartItem ? this.cartItem.quantity : 1)
    },
    confirmItem(id) {
      this.$store.commit('confirmItem', id)
    },
    addOption(option, e) {
      const params = {
        option,
        checked: e.target.checked,
        itemId: this.item.id
      }
      this.$store.commit('addOption', params)
    },
    checkOptionChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_options.find((o) => o.id === id)
    },
    chooseSauce(sauce) {
      const params = {
        sauce,
        itemId: this.item.id
      }
      this.$store.commit('addSauce', params)
    },
    checkSauceChoosed(id) {
      return !!this.cartItem
        && this.cartItem.selected_sauce
        && this.cartItem.selected_sauce.id === id
    }
  },
}
</script>
