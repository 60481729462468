import { get, post } from '@/api/requests'

export default {
  data: () => ({
    isTablet: null,
  }),
  computed: {
    isMobile() {
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
        .test(navigator.userAgent);

      // support Ipad iOs > 13
      if (!isMobile) {
        const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        && !window.MSStream;

        if (isIOS && navigator.maxTouchPoints > 2) {
          isMobile = true;
        }
      }
      return isMobile;
    },
    logoUrl() {
      return window.location.hostname === 'mqr.kz'
        ? 'https://adminqr.qlt.kz/'
        : 'http://37.77.104.231:3636/'
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkISTablet);
    this.checkISTablet()
  },
  methods: {
    async sendTarifForm(form) {
      return post('/application', form)
    },

    async checkISTablet() {
      if (window.innerWidth >= 600) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
    },

    async sendQuestionForm(form) {
      return post('/question', form)
    },

    async getMenuItem(itemId) {
      const response = await get(`/menu/${itemId}`)
      return response.data.result
    },
  }
}
