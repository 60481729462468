<template>
  <header class="landing-header">
    <div class="landing-wrap__header">
      <div class="landing-header__logo">
        <router-link
          :to="{name: 'Landing'}"
          class="landing-header__logo__link"
        >
          <img
            class="landing-header__logo__img"
            src="@/assets/img/landing/qr-logo.png"
            alt="QR Menu"
          >
        </router-link>
      </div>
      <scrollactive
        active-class="landing-header__nav--item-active"
        :offset="80"
        :duration="600"
        :highlight-first-item="true"
        :always-track="true"
        class="landing-header__nav"
      >
        <ul class="landing-header__nav--list">
          <li class="landing-header__nav--item">
            <a
              v-if="fromLanding"
              href="#why-qr"
              class="nav-item-link scrollactive-item"
            >
              Почему QR-menu?
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="nav-item-link scrollactive-item"
            >
              Почему QR-menu?
            </router-link>
          </li>
          <li class="landing-header__nav--item">
            <a
              v-if="fromLanding"
              href="#tarifs"
              class="nav-item-link scrollactive-item"
            >
              Тарифы
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="nav-item-link scrollactive-item"
            >
              Тарифы
            </router-link>
          </li>
          <li class="landing-header__nav--item">
            <a
              v-if="fromLanding"
              href="#faq"
              class="nav-item-link scrollactive-item"
            >
              FAQ
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="nav-item-link scrollactive-item"
            >
              FAQ
            </router-link>
          </li>
          <li class="landing-header__nav--item">
            <a
              v-if="fromLanding"
              href="#contacts"
              class="nav-item-link scrollactive-item"
            >
              Контакты
            </a>
            <router-link
              v-else
              :to="{name: 'Landing'}"
              class="nav-item-link scrollactive-item"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </scrollactive>
      <div class="header-control">
        <img
          class="header-menu-icon"
          src="@/assets/img/landing/menu_nav.png"
          @click="openMobileMenu"
        >
      </div>
      <router-link
        :to="{path: '/alabaita'}"
        class="demo-btn landing-btn"
      >
        Демо
      </router-link>
    </div>
    <MobileMenu
      v-show="isMobileMenuVisible"
      :from-landing="fromLanding"
      @close="closeMobileMenu"
    />
  </header>
</template>

<script>
import MobileMenu from '@/components/modals/MobileMenu.vue';

export default {
  name: 'HeaderMain',
  components: {
    MobileMenu
  },
  props: {
    fromLanding: {
      type: Boolean
    }
  },
  data() {
    return {
      isMobileMenuVisible: false
    }
  },
  methods: {
    openMobileMenu() {
      this.isMobileMenuVisible = true;
    },
    closeMobileMenu() {
      this.isMobileMenuVisible = false;
    },
  }
}
</script>
