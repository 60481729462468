<template>
  <div class="not-found-wrap">
    <HeaderMain
      :from-landing="false"
      class="header-main"
    />
    <div class="not-found-body">
      <img
        class="nf-side-1"
        src="@/assets/img/landing/404-side.png"
      >
      <img
        class="nf-img"
        src="@/assets/img/landing/404.png"
      />
      <span class="nf-text">
        Упс! Такой страницы не существует
      </span>
      <router-link
        :to="{name: 'Landing'}"
        class="landing-btn"
      >
        На главную
      </router-link>
      <img
        class="nf-side-2"
        src="@/assets/img/landing/404-side2.png"
      >
    </div>
    <FooterMain
      :from-landing="false"
      class="footer-main"
    />
  </div>
</template>

<script>
import FooterMain from '@/components/landing/FooterMain.vue'
import HeaderMain from '@/components/landing/HeaderMain.vue'

export default {
  name: 'NotFound',
  components: {
    FooterMain,
    HeaderMain
  }
}
</script>

<style scoped>
  .not-found-wrap {
    background: #242B33;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .footer-main {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    flex: none;
  }
  .nf-side-2 {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 20%;
    height: auto;
  }
  .nf-side-1 {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 15%;
    height: auto;
  }
  .nf-text {
    font-family: Montserrat;
    font-weight: 900;
    font-size: 2vw;
    line-height: 2vw;
    text-align: center;

    color: #FFFFFF;
  }
  .not-found-body {
    display: grid;
    justify-content: center;
    flex: auto;
    grid-template-columns: 35%;
  }
  .nf-img {
    margin: auto auto 6% auto;
    max-width: 100%;
  }
  .header-main {
    flex: none;
    background: unset !important;
  }

  @media screen and (min-width: 812px){
    .landing-btn {
      display: none;
    }
  }

  @media screen and (max-width: 812px) {
    .nf-side-1, .nf-side-2 {
      display: none;
    }
    .footer-main {
      position: unset;
    }
    .not-found-wrap {
      height: unset;
    }
    .not-found-body {
      grid-template-columns: 79%;
      grid-template-rows: fit-content(1%) fit-content(1%) fit-content(1%);
      justify-items: center;
    }
    .nf-text {
      font-size: 5.5vw;
      line-height: 6vw;
      padding: 10% 0 20%;
    }
    .nf-img {
      max-width: 75%;
      margin: 25% 0 0;
    }
    .landing-btn {
      font-size: 5.2vw;
      text-decoration: none;
      text-align: center;
      width: 70%;
      padding: 6% 0;
      margin-bottom: 25%;
    }
  }
</style>
