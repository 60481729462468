<template>
  <div class="cart-wrap">
    <BackHeader
      v-if="restaurant && !isTablet"
      :item-id="returnItem"
    />
    <div class="cart">
      <div
        v-if="!isTablet"
        class="cart_wrap"
      >
        <div class="restaurant-body__menu-category-title">
          <span class="restaurant-body__menu-category-title__text">
            Мой заказ
          </span>
        </div>
        <div class="subtitle">
          Если вы готовы сделать заказ, можете позвать официанта
        </div>
      </div>
      <div class="restaurant-body__menu-category__list">
        <MenuItem
          v-for="(item, index) in cart.filter((i) => i.confirmed)"
          :key="index"
          :item="item"
          @modal_opened="showModalItem(arguments[0])"
          @modal_details_opened="showModalDetails(arguments[0])"
          @sameAdded="addSame(arguments[0], arguments[1])"
        />
      </div>
      <div class="total">
        <div
          v-if="restaurant.service"
          class="total-service"
        >
          <span class="total-service-text">
            Обслуживание
          </span>
          <span class="total-service-percent">
            {{ restaurant.service }}%
          </span>
        </div>
        <div class="total-price">
          <span class="left">Итого</span>
          <span class="right">{{ total + (total * restaurant.service / 100)
            | formatPrice(currency) }}</span>
        </div>
        <div
          v-if="restaurant.comment"
          class="total-comment"
        >
          <span class="total-comment-text">
            {{ restaurant.comment }}
          </span>
        </div>
      </div>
    </div>
    <MenuItemDetails
      v-show="isModalDetailsVisible"
      :details="details"
      @closed="closeModalDetails"
    />
    <MenuItemModal
      v-show="isModalItemVisible"
      :image="itemModal.logo"
      :name="itemModal.name"
      :ingredients="itemModal.ingredients"
      @close="closeModalItem"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuItem from '@/components/MenuItem.vue'
import BackHeader from '@/components/BackHeader.vue'
import api from '@/mixins/api'
import MenuItemDetails from '@/components/modals/MenuItemDetails.vue'
import MenuItemModal from '@/components/modals/MenuItemModal.vue'

export default {
  name: 'Cart',
  components: {
    BackHeader,
    MenuItem,
    MenuItemModal,
    MenuItemDetails
  },
  filters: {
    formatPrice(price, currency) {
      return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      ${currency}`
    }
  },
  mixins: [api],
  data() {
    return {
      returnItem: null,
      isModalDetailsVisible: false,
      details: null,
      isModalItemVisible: false,
      itemModal: {
        logo: '',
        name: '',
        ingredients: ''
      },
    }
  },
  computed: {
    ...mapGetters([
      'total',
      'cart',
      'restaurant'
    ]),
    currency() {
      const first = this.cart[0]
      return first ? first.currency : ''
    }
  },
  mounted() {
    const firstItem = this.cart.filter((i) => i.confirmed)[0]
    this.returnItem = firstItem.id
  },
  methods: {
    addSame(item, num) {
      const newItem = JSON.parse(JSON.stringify(item))
      newItem.id = `${num}_${newItem.id}`
      this.$store.dispatch('addItemToCartConfirmed', newItem)
    },
    showModalItem(item) {
      this.itemModal.logo = this.logoUrl + item.photo
      this.itemModal.name = item.name
      this.itemModal.ingredients = item.ingredient
      this.isModalItemVisible = true
      document.body.style.overflowY = 'hidden';
    },
    closeModalItem() {
      this.isModalItemVisible = false
    },
    showModalDetails(item) {
      this.details = item
      this.isModalDetailsVisible = true
    },
    closeModalDetails() {
      this.isModalDetailsVisible = false
    },
  }
}
</script>
