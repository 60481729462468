<template>
  <div>
    <router-view />
    <CartButton
      v-if="$route.name !== 'Landing'
        && $route.name !== 'NotFound' && !isTablet"
    />
  </div>
</template>

<script>

import CartButton from '@/components/mobile/CartButton.vue'
import api from '@/mixins/api';

export default {
  name: 'MobileView',
  components: {
    CartButton
  },
  mixins: [api]
}
</script>
