<template>
  <div>
    <span class="modal-body__title tooltip-item">
      Режим работы
    </span>
    <template>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Понедельник
        </span>
        <span
          v-if="schedule[0].hour_b && schedule[0].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[0].hour_b.substr(0, 5)
            + ' до ' + schedule[0].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Вторник
        </span>
        <span
          v-if="schedule[1].hour_b && schedule[1].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[1].hour_b.substr(0, 5)
            + ' до ' + schedule[1].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Среда
        </span>
        <span
          v-if="schedule[2].hour_b && schedule[2].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[2].hour_b.substr(0, 5)
            + ' до ' + schedule[2].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Четверг
        </span>
        <span
          v-if="schedule[3].hour_b && schedule[3].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[3].hour_b.substr(0, 5)
            + ' до ' + schedule[3].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Пятница
        </span>
        <span
          v-if="schedule[4].hour_b && schedule[4].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[4].hour_b.substr(0, 5)
            + ' до ' + schedule[4].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Суббота
        </span>
        <span
          v-if="schedule[5].hour_b && schedule[5].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[5].hour_b.substr(0, 5)
            + ' до ' + schedule[5].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
      <div class="modal-body__item tooltip-item">
        <span class="modal-body__item--title">
          Воскресенье
        </span>
        <span
          v-if="schedule[6].hour_b && schedule[6].hour_e"
          class="modal-body__item--info"
        >
          {{ 'с ' + schedule[6].hour_b.substr(0, 5)
            + ' до ' + schedule[6].hour_e.substr(0, 5) }}
        </span>
        <span
          v-else
          class="modal-body__item--info"
        >
          Выходной
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TooltipBoxSchedule',
  props: {
    schedule: {
      type: Array,
      default: () => []
    }
  }
}
</script>
