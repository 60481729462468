<template>
  <transition name="modal-fade">
    <div
      class="modal-backdrop"
      @click.self="close"
    >
      <div class="modal-success-tarif">
        <div class="modal-success-tarif__head">
          <span
            class="modal-success-tarif__head--close"
            @click="close"
          />
        </div>
        <div class="modal-success-tarif__body">
          <span class="modal-success-tarif__body--title">
            Супер
          </span>
          <p class="modal-success-tarif__body--description">
            Ваша заявка отправлена.
            Наши менеджеры свяжуться с вами в ближайшее время.
          </p>
          <button
            class="modal-tarif-choose__body--btn"
            @click="close"
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SuccessTarif',
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
