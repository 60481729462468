<template>
  <div>
    <HeaderTop v-if="$route.name !== 'Landing' && $route.name !== 'NotFound' && isMobile && load" />
    <MobileView v-if="isMobile" />
    <DesktopView v-else />
  </div>
</template>

<script>
import HeaderTop from "@/components/HeaderTop.vue";
import DesktopView from "@/layouts/Desktop.vue";
import MobileView from "@/layouts/Mobile.vue";
import api from "@/mixins/api";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    HeaderTop,
    DesktopView,
    MobileView
  },
  computed: {
    ...mapGetters({
      load: 'load'
    })
  },
  mixins: [api]
};
</script>
