<template>
  <div v-if="!isLoading" class="landing">
    <Loading v-if="!load" @load="changeLoad" />
    <template v-else>
      <main class="landing-main">
        <HeaderMain :from-landing="true" />
        <section class="landing-main__intro">
          <div class="landing-wrap">
            <div class="title">
              <h1 class="intro--title">
                QR-menu – Электронное меню для улучшения качества сервиса.
              </h1>
              <p class="intro--subtitle">
                Сделайте опыт клиента безопасным, быстрым и комфортным.
              </p>
              <div class="intro--control">
                <button
                  class="intro-application landing-btn"
                  @click="showModal('free', null, null, null)"
                >
                  Создать меню
                </button>
                <router-link :to="{ path: '/alabaita' }" class="intro-demo">
                  Демо
                </router-link>
              </div>
            </div>
            <div class="qlt">
              <div class="qlt-logo">
                <img src="@/assets/img/landing/qlt-logo.png" alt="" />
              </div>
              <div class="qlt-text">
                IT решения в любой сфере
              </div>
              <div class="qlt-btn-wrapper">
                <a @click="showQltModal" class="intro-demo qlt-btn">
                  Подробнее
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="why-qr" class="landing-main__benefits">
          <div class="landing-wrap">
            <div class="section--title">
              <div class="section--title--border" />
              Преимущества электронного меню
            </div>
            <div class="landing-main__benefits__list">
              <div class="landing-main__benefits__item">
                <div class="benefits--item--content">
                  <img class="icon-benefit" src="@/assets/img/landing/icons/dont-touch_1.png" />
                  <div class="benefits--item--content__description">
                    <span class="item--description--title">
                      Безопасность
                      <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
                    </span>
                    <p class="item--description--body">
                      Бесконтактное меню делает опыт клиента комфортным и безопасным.
                    </p>
                  </div>
                </div>
              </div>
              <div class="landing-main__benefits__item">
                <div class="benefits--item--content">
                  <img class="icon-benefit" src="@/assets/img/landing/icons/clock_2.png" />
                  <div class="benefits--item--content__description">
                    <span class="item--description--title">
                      Время
                      <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
                    </span>
                    <p class="item--description--body">
                      Доступ к меню с телефона экономит время клиента и оптимизирует время
                      официанта.
                    </p>
                  </div>
                </div>
              </div>
              <div class="landing-main__benefits__item">
                <div class="benefits--item--content">
                  <img class="icon-benefit" src="@/assets/img/landing/icons/money_1.png" />
                  <div class="benefits--item--content__description">
                    <span class="item--description--title">
                      Экономия
                      <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
                    </span>
                    <p class="item--description--body">
                      Редактирование и обновление электронного меню занимает 5 минут и не требует
                      расходов на печать
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="how-works" class="landing-main--how-works">
          <div class="landing-wrap">
            <div class="how-works-title section--title">
              <div class="section--title--border" />
              Как работает QR-menu?
            </div>
            <img class="how-works-img" src="@/assets/img/landing/how_works.png" />
            <div class="how-works-steps-list">
              <div class="how-works-step">
                <img class="step-img" src="@/assets/img/landing/scan_qr.png" />
              </div>
              <div class="how-works-step">
                <img class="step-img" src="@/assets/img/landing/choose_menu.png" />
              </div>
              <div class="how-works-step">
                <img class="step-img" src="@/assets/img/landing/show_order.png" />
              </div>
            </div>
          </div>
        </section>
        <section id="additions" class="landing-main--additionals">
          <div class="landing-wrap">
            <div class="section--title">
              <div class="section--title--border" />
              Дополнительные преимущества
            </div>
            <div class="additionals--body">
              <div class="additionals--description">
                <div class="additionals--description__sec">
                  <span class="additionals--description__sec--title">
                    Удобная и приятная навигация
                  </span>
                  <p class="additionals--description__sec--text">
                    Деление меню по категориям упрощает поиск блюда, а динамичные фотографии и
                    описание делают поиск приятнее.
                  </p>
                  <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
                </div>
                <div class="additionals--description__sec">
                  <span class="additionals--description__sec--title">
                    Снижение риска ошибок в заказе
                  </span>
                  <p class="additionals--description__sec--text">
                    Демонстрация готового списка блюд официанту снижает риск возниковения ошибки в
                    заказе.
                  </p>
                  <img class="content__icon--img" src="@/assets/img/landing/group_49.png" />
                </div>
                <div class="additionals--description__sec">
                  <span class="additionals--description__sec--title">
                    Ожидания клиента
                  </span>
                  <p class="additionals--description__sec--text">
                    Электронное меню позволяет «скрывать» позиции, которых временно нет в наличии.
                  </p>
                </div>
              </div>
              <div class="additionals--video">
                <img
                  class="additionals--video--img"
                  src="@/assets/img/landing/placeholder-video.png"
                />
              </div>
              <div class="additionals--side">
                <img class="additionals-side-img" src="@/assets/img/landing/side_1.png" />
              </div>
            </div>
          </div>
        </section>
        <section id="tarifs" class="landing-main--tarifs">
          <div class="landing-wrap">
            <div class="section--title">
              <div class="section--title--border" />
              <span class="tarifs-title">
                Абсолютно бесплатно
              </span>
            </div>
            <div class="tarifs--check-list">
              <span class="tarifs--check-list--title">
                Вы получаете:
              </span>
              <ul class="tarifs--list__el--checklist">
                <li class="tarifs--list__el--checklist--el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Загрузка меню и генерация QR-кода
                </li>
                <li class="tarifs--list__el--checklist--el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Функция отобразить/скрыть позицию в меню
                </li>
                <li class="tarifs--list__el--checklist--el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Печать QR-кода с логотипом заведения
                </li>
                <li class="tarifs--list__el--checklist--el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Личный кабинет заведения для редактирования и управления меню
                </li>
              </ul>
            </div>
            <div class="main--form">
              <div class="free-title section--title">
                <span class="tarifs-title">
                  Навсегда
                  <p class="tarifs-decription">
                    Мы даем возможность бизнесу получить электронное меню и оценить его преимущества
                    навсегда
                  </p>
                </span>
              </div>
              <div class="main-form__box-body">
                <div class="main-form__box">
                  <span class="main-form__box--title">
                    Повысьте качество сервиса с QR-menu
                  </span>
                  <div class="main-form__box-control">
                    <button
                      class="main-form__box-control--free-btn"
                      @click="showModal('free', null, null, null)"
                    >
                      Создать меню
                    </button>
                  </div>
                </div>
                <div class="main-form__box-images">
                  <img class="main-form__box-img" src="@/assets/img/landing/menu.png" />
                </div>
              </div>
              <ul class="main-form__box--checklist">
                <li class="main-form__box--checklist__el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Бесплатно навсегда
                </li>
                <li class="main-form__box--checklist__el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Быстрая установка
                </li>
                <li class="main-form__box--checklist__el">
                  <img class="checklist--el-icon" src="@/assets/img/landing/icons/checklist.png" />
                  Отмена в любое время
                </li>
              </ul>
            </div>
            <button
              class="main-form__box-control--free-btn mobile-free-btn"
              @click="showModal('free', null, null, null)"
            >
              Попробовать бесплатно
            </button>
          </div>
        </section>
        <div class="trust">
          <div class="landing-wrap trust__wrapper">
            <div class="section--title trust__title">
              <div class="trust-text">
                Нам доверяют
              </div>
              <div class="trust__buttons">
                <button class="trust__button trust__button-prev">
                  &#8249;
                </button>
                <button class="trust__button trust__button-next">
                  &#8250;
                </button>
              </div>
            </div>
            <div class="trust__arrows"></div>
            <div class="trust__wrap" @click="openPage">
              <div
                v-for="(partner, index) in partners"
                :key="`partner-${index}`"
                class="trust__slide"
              >
                <img
                  :src="`${logo}${partner.image}`"
                  :data-link="partner.link"
                  alt="trust-img"
                  class="trust__photo"
                />
              </div>
            </div>
          </div>
        </div>
        <section id="faq" class="landing-main--qa">
          <div class="qa-body">
            <div class="additionals--side-2">
              <img class="additionals-side-img" src="@/assets/img/landing/side_2.png" />
            </div>
            <div class="additionals--side-3">
              <img class="additionals-side-img" src="@/assets/img/landing/side_3.png" />
            </div>
            <div class="landing-wrap">
              <div class="section--title">
                <div class="section--title--border" />
                Часто задаваемые вопросы
              </div>
              
              <div class="main-qa--list">
                <template v-for="(item, index) in questions">
                  <div :key="`q${index}`" class="main-qa--list__el" @click="toggle(index)">
                    <p class="main-qa--list__el--text">
                      {{ item.question }}
                    </p>
                    <img
                      class="main-qa--list__el--arrow"
                      :class="{ 'arrow-reversed': item.toggled }"
                      src="@/assets/img/landing/down.svg"
                    />
                  </div>
                  <div v-if="item.toggled" :key="`a${index}`" class="main-qa--list__el--answer">
                    <p class="main-qa--list__el--answer--text">
                      {{ item.answer }}
                    </p>
                  </div>
                </template>
              </div>
              
            </div>
          </div>
        </section>
        <section id="contacts" class="landing-main--contacts">
          <div class="landing-wrap">
            <div class="section--title">
              <div class="section--title--border" />
              <span class="tarifs-title">
                Контакты
              </span>
            </div>
            <div class="landing-main--contacts-body">
              <div class="landing-main--contacts-body--list">
                <div class="landing-main--contacts-body--list-el">
                  <span class="landing-main--contacts-body--list-el-name">
                    Позвонить
                  </span>
                  <a href="tel:+7(747)-353-26-12" class="landing-main--contacts-body--list-el-text">
                    +7(747)-353-26-12
                  </a>
                </div>
                <img src="@/assets/img/landing/group_49.png" />
                <div class="landing-main--contacts-body--list-el">
                  <span class="landing-main--contacts-body--list-el-name">
                    Написать
                  </span>
                  <a
                    href="mailto:a.abylaikhan@qlt.kz"
                    target="_blank"
                    class="landing-main--contacts-body--list-el-text"
                  >
                    a.abylaikhan@qlt.kz
                  </a>
                </div>
                <div class="landing-main--contacts__social">
                  <a href="https://wa.me/+77473532612" target="_blank">
                    <img
                      class="landing-main--contacts__social-icon"
                      src="@/assets/img/landing/whatsapp.svg"
                    />
                  </a>
                  <a href="https://telegram.me/Absamaat" target="_blank">
                    <img
                      class="landing-main--contacts__social-icon"
                      src="@/assets/img/landing/telegram.png"
                    />
                  </a>
                </div>
              </div>
              <div class="landing-main--contacts-body--form">
                <div class="landing-main--contacts-body--form--wrap">
                  <span class="contacts-body--form__title">
                    Остались вопросы?
                  </span>
                  <span class="contacts-body--form__text">
                    Мы всегда готовы ответить на ваши вопросы и выслушать пожелания по работе
                    сервиса.
                  </span>
                  <div class="contacts-body--form__inputs">
                    <div class="contacts-body--form__inputs--group">
                      <label class="contacts-body--form__label">
                        Ваше имя
                      </label>
                      <input
                        v-model="question.name"
                        placeholder="Иван"
                        class="main-form__box-control--phone small-inp"
                      />
                    </div>
                    <div class="contacts-body--form__inputs--group">
                      <label class="contacts-body--form__label">
                        Номер телефона
                      </label>
                      <input
                        v-model="question.telephone"
                        v-mask="'+7-###-###-##-##'"
                        type="tel"
                        placeholder="+7___-___-__-__"
                        class="main-form__box-control--phone small-inp"
                      />
                    </div>
                    <button
                      class="main-form__box-control--free-btn
                        small-inp-btn"
                      :disabled="!question.name || !question.telephone"
                      @click="sendQuestion"
                    >
                      Отправить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterMain :from-landing="true" />
      <RegistrationGratitude v-show="isModal2Visible" @close="closeModal2"> </RegistrationGratitude>
      <ChooseTarif
        v-show="isModalVisible"
        :type="tarifDetails.name"
        @close="closeModal"
        @sent="showModal2"
      >
        <template #prices>
          <div v-if="tarifDetails.name !== 'free'" class="modal-tarif-choose__body--free">
            30 дней бесплатно
          </div>
          <div v-if="tarifDetails.name !== 'free'" class="modal-tarif-choose__body--price">
            <div class="modal-tarif-choose__body--price-group">
              <span class="modal-tarif-choose__body--price-group-name">
                Месяц
              </span>
              <span class="modal-tarif-choose__body--price-group-tg">
                {{ tarifDetails.monthlyPrice }} ТГ
              </span>
            </div>
            <div v-if="tarifDetails.periodPrice" class="modal-tarif-choose__body--price-group">
              <span class="modal-tarif-choose__body--price-group-name">
                {{ tarifDetails.name === "half_year" ? "6 месяцев" : "12 месяцев" }}
              </span>
              <span class="modal-tarif-choose__body--price-group-tg">
                {{ tarifDetails.periodPrice }} ТГ
              </span>
            </div>
            <div class="modal-tarif-choose__body--price-group">
              <span class="modal-tarif-choose__body--price-group-name">
                Оплата после пробного периода
              </span>
              <span class="modal-tarif-choose__body--price-group-tg">
                {{ tarifDetails.afterFreeTrial }} ТГ
              </span>
            </div>
          </div>
        </template>
      </ChooseTarif>
      <SuccessTarif v-show="isSuccessModalVisible" @close="closeSuccessModal" />
      <QltModal v-show="qltModalVisible" @close="closeQltModal" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import ChooseTarif from "@/components/modals/ChooseTarif.vue";
import RegistrationGratitude from "@/components/modals/RegistrationGratitude.vue";
import QltModal from "@/components/modals/Qlt.vue";
import SuccessTarif from "@/components/modals/SuccessTarif.vue";
import FooterMain from "@/components/landing/FooterMain.vue";
import HeaderMain from "@/components/landing/HeaderMain.vue";
import Loading from "@/components/Loading.vue";
import qa from "@/qa_dictionary";
import api from "@/mixins/api";

export default {
  name: "Landing",
  components: {
    ChooseTarif,
    RegistrationGratitude,
    SuccessTarif,
    FooterMain,
    HeaderMain,
    Loading,
    QltModal
  },
  mixins: [api],
  data() {
    return {
      isModalVisible: false,
      isModal2Visible: false,
      isMobileMenuVisible: false,
      isSuccessModalVisible: false,
      qltModalVisible: false,
      load: false,
      tarifDetails: {
        name: "",
        monthlyPrice: "",
        periodPrice: "",
        afterFreeTrial: ""
      },
      question: {
        telephone: "",
        name: ""
      },
      freePhone: "",
      questions: qa,
      drag: false,
      time: null
    };
  },
  computed: {
    ...mapGetters({
      partners: "partners",
      isLoading: "isLoading"
    }),
    logo() {
      return window.location.hostname === "mqr.kz"
        ? "https://adminqr.qlt.kz/"
        : "http://37.77.104.231:3636/";
    }
  },
  updated(){
    let _this = this;
    $(".trust__wrap").on("swipe", function() {
      _this.drag = true;
    });
    $(".trust__wrap").on("afterChange", function() {
      _this.drag = false;
    });
    $(".trust__wrap").slick({
      dots: false,
      infinite: true,
      speed: 300,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      arrows: true,
      centerMode: false,
      adaptiveHeight: false,
      prevArrow: $(".trust__button-prev"),
      nextArrow: $(".trust__button-next"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            arrows: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            arrows: true
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            arrows: true
          }
        }
      ]
    });
  },
  async mounted() { 
    this.talkMeInit();
    /* eslint-disable */
    /* let _this = this;
    $(".trust__wrap").on("swipe", function() {
      _this.drag = true;
    });
    $(".trust__wrap").on("afterChange", function() {
      _this.drag = false;
    }); */
    await this.$store.dispatch("getPartners");
    const debounce = fn => {
      let frame;
      return (...params) => {
        if (frame) {
          cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      };
    };
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    };
    document.addEventListener("scroll", debounce(storeScroll), { passive: true });
    storeScroll();
    /* slider settings */
    /* $(".trust__wrap").slick({
      dots: false,
      infinite: true,
      speed: 300,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      arrows: true,
      centerMode: false,
      adaptiveHeight: false,
      prevArrow: $(".trust__button-prev"),
      nextArrow: $(".trust__button-next"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            arrows: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            arrows: true
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            arrows: true
          }
        }
      ]
    }); */
  },
  
  beforeRouteLeave(to, from, next) {
    try {
      const elements = document.getElementsByClassName("online-chat-root online-chat-root-TalkMe");
      while (elements.length > 0) elements[0].remove();
    } catch (e) {
      console.warn(e);
    }
    next();
  },
  methods: {
    timeout() {
      this.time = setTimeout(() => {
        this.qltModalVisible = false;
      }, 5000);
    },
    changeLoad(value) {
      this.load = value;
    },
    talkMeInit() {
      (function(d, w, m) {
        window.supportAPIMethod = m;
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.id = "supportScript";
        s.charset = "utf-8";
        s.async = true;
        var id = "b472a25e361e6949663c02a8bbf82d6c";
        s.src = "https://lcab.talk-me.ru/support/support.js?h=" + id;
        var sc = d.getElementsByTagName("script")[0];
        w[m] =
          w[m] ||
          function() {
            (w[m].q = w[m].q || []).push(arguments);
          };
        if (sc) sc.parentNode.insertBefore(s, sc);
        else d.documentElement.firstChild.appendChild(s);
      })(document, window, "TalkMe");
    },
    showModal(category, monthly, period, afterFree) {
      this.tarifDetails.name = category;
      this.tarifDetails.monthlyPrice = monthly;
      this.tarifDetails.periodPrice = period;
      this.tarifDetails.afterFreeTrial = afterFree;
      this.isModalVisible = true;
    },
    showModal2() {
      this.isModalVisible = false;
      this.isModal2Visible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeModal2() {
      this.isModal2Visible = false;
    },
    closeQltModal() {
      this.qltModalVisible = false;
      clearTimeout(this.time);
    },
    openSuccessModal() {
      this.isModalVisible = false;
      this.isSuccessModalVisible = true;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
    },
    toggle(index) {
      this.questions[index].toggled = !this.questions[index].toggled;
    },
    sendQuestion() {
      this.sendQuestionForm(this.question)
        .then(() => {
          this.question.name = "";
          this.question.telephone = "";
          this.openSuccessModal();
        })
        .catch(error => {
          console.log(error);
        });
    },
    openPage({ target: t }) {
      const link = t.dataset.link;
      if (!this.drag && link) {
        window.open(`https://${link}`, "_blank");
      }
    },
    showQltModal() {
      this.qltModalVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.qlt {
  position: absolute;
  bottom: 10%;
  width: 50%;
  .qlt-logo {
    width: 25%;
    img {
      width: 100%;
    }
  }
  .qlt-text {
    margin-bottom: 20px;
    font-family: Montserrat;
    font-size: 18px;
    color: white;
  }
}
@media screen and (min-width: 375px) and (max-height: 667px) {
  .qlt {
    width: 100%;
    .qlt-text {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .qlt {
    box-sizing: border-box;
    padding: 0 5%;
    bottom: 0;
    width: 100%;
    .qlt-logo {
      margin-right: 10px;
    }
    .qlt-text {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

// @media screen and (max-width: 499px) {
//   .qlt {
//     justify-content: space-between;
//     display: flex;
//     flex-wrap: wrap;
//     .qlt-text {
//       order: 1;
//       width: 100%;
//       text-align: center;
//       margin-top: 5px;
//       margin-bottom: 0;
//     }
//     .qlt-btn-wrapper {
//       width: auto;
//     }
//   }
// }
@media screen and (max-width: 410px) {
  .qlt {
    position: static;
    margin-top: 20px;
  }
}
</style>
